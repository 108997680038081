const getZipFromGoogle = async (isMapLoaded, latitude, longitude) => {
	if (isMapLoaded) {
		const geocoder = new window.google.maps.Geocoder()
		let response
		await geocoder.geocode(
			{ location: { lat: latitude, lng: longitude } },
			(results, status) => {
				if (status === 'OK') {
					response = results
				}
			}
		)

		return response
	}
}

export default { getZipFromGoogle }
