import cc from 'classcat'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HeaderMenuPopup from './form/header-menu-popup'
import { logoutUser } from '../slices/rootSlice'
import '../styles/header.module.css'

export function HeaderWithoutNav({ phone, isLoggedIn, ...props }) {
	const dispatch = useDispatch()
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	let user = useSelector((state) => state.root.user)

	const handleAccountClick = () => {}

	const handleLogout = () => {
		// Dispatch action to log the user out
		dispatch(logoutUser())
	}

	return (
		<>
			<header>
				<div
					className={cc([
						'font-serif py-4',
						props.bgColor ? props.bgColor : 'bg-brand-page-bg'
					])}
				>
					<div className='flex flex-row px-4 lg:px-8 items-center relative z-1 '>
						<div
							className='flex items-center justify-start'
							style={{ flexBasis: '20%', minHeight: '40px' }}
						>
							<Link href='/'>
								<Image
									src={'/stuf-logo-horizontal-blue.svg'}
									width={107}
									height={28}
									alt='Stuf Logo'
								/>
							</Link>
						</div>
						<div
							className='header-nav-options justify-center space-x-8 hidden lg:flex'
							style={{ flexBasis: '62%' }}
						></div>
						<div
							className='header-phone-nbr hidden lg:flex items-center justify-end text-brand-charcoal-dark mr-4'
							style={{ flexBasis: '15%' }}
						>
							{phone}
						</div>
						{isLoggedIn && (
							<div>
								<div className='header-initial-circle hidden lg:flex items-center justify-center'>
									<div
										className='flex items-center justify-center rounded-full bg-brand-periwinkle text-brand-dark-blue hover:cursor-pointer'
										style={{
											height: '40px',
											width: '40px'
										}}
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										<span className='font-base font-bold uppercase'>
											{user?.nameInitials}
										</span>
									</div>
								</div>
								<HeaderMenuPopup
									className=''
									isOpen={isMenuOpen}
									dashboardType={user?.dashboardType}
									handleLogout={handleLogout}
								/>
							</div>
						)}
						<div
							className='header-book-now hidden lg:flex items-center justify-end space-x-6 hover:cursor-pointer'
							style={{ flexBasis: '3%' }}
							onClick={handleAccountClick}
						/>
					</div>
				</div>
			</header>
		</>
	)
}
