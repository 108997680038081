import InsuranceIcon from './insurance-icon'

const InsuranceInfo = ({ insurance }) => {
	return (
		<div className='flex items-center gap-3 lg:gap-6 p-3'>
			<div className='rounded-full bg-brand-beige p-2'>
				<InsuranceIcon id={insurance.id} />
			</div>
			<div className='flex-1'>
				<div className='flex justify-between font-bold w-full'>
					<div>{insurance.name}</div>
					<div>+${insurance.premium}/mo</div>
				</div>
				<p className='text-sm'>{insurance.description}</p>
			</div>
		</div>
	)
}

export default InsuranceInfo
