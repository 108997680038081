import NextLink from 'next/link'

// TODO: use single styling solution (tailwind / vanilla css / MUI styles)
export const Link = ({
	href,
	passHref,
	variant = 'light',
	bold = false,
	children,
	target,
	className = ''
}) => (
	<NextLink legacyBehavior href={href} passHref={passHref}>
		{variant === 'img' ? (
			<a target={target}>{children}</a>
		) : (
			<a
				target={target}
				className={`
	 	  ${className}
          ${variant === 'light' ? 'text-white' : 'text-brand-charcoal-dark'} 
          ${bold ? 'font-bold' : ''}
          font-serif
          flex
          items-center
          border-b-2
          border-transparent
          hover:border-gray-300
          focus:border-gray-300
          py-1
          focus:outline-none
          focus:ring
        `}
			>
				{children}
			</a>
		)}
	</NextLink>
)
