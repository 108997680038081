export const Text = ({
	children,
	size = 'sm',
	font = 'serif',
	variant = 'light'
}) => {
	const sizes = {
		xs: 'text-xs',
		sm: 'text-sm',
		base: 'text-base',
		lg: 'text-lg',
		xl: 'text-xl',
		'2xl': 'text-2xl',
		'3xl': 'text-3xl',
		'4xl': 'text-4xl',
		'5xl': 'text-5xl',
		'6xl': 'text-6xl',
		'7xl': 'text-7xl',
		'8xl': 'text-8xl',
		'9xl': 'text-9xl'
	}
	const fonts = {
		sans: 'font-sans',
		serif: 'font-serif'
	}
	const variants = {
		light: 'text-brand-white',
		dark: 'text-brand-charcoal-dark',
		black: 'text-brand-black'
	}

	return (
		<p className={`${fonts[font]} ${sizes[size]} ${variants[variant]}`}>
			{children}
		</p>
	)
}
