import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'next-i18next'
import {
	postCalculateMoveInEstimate,
	resetPostVerifyPromoCodeStatus,
	resetPromoCode,
	postVerifyPromoCode,
	resetPostCalculateMoveInEstimateStatus
} from '../slices/bookingSlice'

import { TRANSLATIONS } from '../util/constants'
import { Loading } from '../atoms/loading'
import PromoCodeChip from '../atoms/promo-code-chip'

const PromoCode = () => {
	const { t } = useTranslation(TRANSLATIONS)
	const dispatch = useDispatch()
	const [promoCodeState, setPromoCodeState] = useState('closed')
	const [promoCode, setPromoCode] = useState('')
	const [promoError, setPromoError] = useState(false)
	const [calculatePaymentAttempts, setCalculatePaymentAttempts] = useState(0)
	const facilityInfo = useSelector((state) => state.booking.facility)
	const unitInfo = useSelector((state) => state.booking.unit)
	const paymentInfo = useSelector((state) => state.booking.payment)
	const postCalculateMoveInEstimateStatus = useSelector(
		(state) => state.booking.postCalculateMoveInEstimateStatus
	)
	const postVerifyPromoCodeStatus = useSelector(
		(state) => state.booking.postVerifyPromoCodeStatus
	)
	const cartOpen = useSelector((state) => state.booking.cartOpen)

	useEffect(() => {
		switch (postCalculateMoveInEstimateStatus) {
			case 'FULFILLED':
				setCalculatePaymentAttempts(0)
				dispatch(resetPostCalculateMoveInEstimateStatus())
				break
			case 'REJECTED':
				if (calculatePaymentAttempts < 4) {
					setCalculatePaymentAttempts((prevState) => prevState + 1)
					dispatch(
						postCalculateMoveInEstimate({
							body: {
								facility_id: facilityInfo.facility_id,
								monthly_price: unitInfo[0].price,
								move_in_date: paymentInfo.move_in_date,
								...(paymentInfo.insurance_id && {
									insurance_id: paymentInfo.insurance_id
								}),
								...(paymentInfo.membership_id && {
									membership_type_id: paymentInfo.membership_id
								}),
								...(paymentInfo.promo_id
									? { promo_id: paymentInfo.promo_id }
									: facilityInfo.discount_id != null && {
											discount_id: facilityInfo.discount_id
									  })
							},
							...(cartOpen && { fields: ['schedule'] })
						})
					)
				} else {
					// TODO error handling
					dispatch(resetPostCalculateMoveInEstimateStatus())
				}
			default:
				break
		}
	}, [postCalculateMoveInEstimateStatus])

	useEffect(() => {
		switch (postVerifyPromoCodeStatus) {
			case 'FULFILLED':
				dispatch(
					postCalculateMoveInEstimate({
						body: {
							facility_id: facilityInfo.id,
							monthly_price: unitInfo[0].price,
							move_in_date: paymentInfo.move_in_date,
							promo_id: paymentInfo.promo_id,
							...(paymentInfo.insurance_id && {
								insurance_id: paymentInfo.insurance_id
							}),
							...(paymentInfo.membership_id && {
								membership_type_id: paymentInfo.membership_id
							})
						},
						...(cartOpen && { fields: ['schedule'] })
					})
				)
				setPromoCodeState('valid')
				dispatch(resetPostVerifyPromoCodeStatus())
				break
			case 'REJECTED':
				setPromoCodeState('open')
				setPromoError(true)
				setPromoCode('')
				dispatch(resetPostVerifyPromoCodeStatus())
				// TODO: error handling
				break
			case 'PENDING':
				setPromoCodeState('loading')
			default:
				break
		}
	}, [postVerifyPromoCodeStatus])

	const validatePromoCode = () => {
		setPromoError(false)
		dispatch(
			postVerifyPromoCode({
				facility_id: facilityInfo.id,
				promo_code: promoCode
			})
		)
	}

	const removePromoCode = () => {
		dispatch(resetPostVerifyPromoCodeStatus())
		setPromoCode('')
		setPromoCodeState('closed')
		dispatch(resetPromoCode())
		dispatch(
			postCalculateMoveInEstimate({
				body: {
					facility_id: facilityInfo.id,
					monthly_price: unitInfo[0].price,
					move_in_date: paymentInfo.move_in_date,
					...(paymentInfo.insurance_id && {
						insurance_id: paymentInfo.insurance_id
					}),
					...(paymentInfo.membership_id && {
						membership_type_id: paymentInfo.membership_id
					}),
					...(facilityInfo.discount_id !== null && {
						discount_id: facilityInfo.discount_id
					})
				},
				...(cartOpen && { fields: ['schedule'] })
			})
		)
	}

	if (paymentInfo.promo_id) {
		return (
			<PromoCodeChip code={paymentInfo.promo_code} onClick={removePromoCode} />
		)
	}
	switch (promoCodeState) {
		case 'open':
			return (
				<>
					<div className='flex gap-4 items-center'>
						<TextField
							data-cy='booking-promoCodeInput'
							fullWidth
							value={promoCode}
							inputProps={{ sx: { padding: '8px 12px' } }}
							onChange={(e) => setPromoCode(e.target.value)}
						/>
						<Button
							data-cy='booking-promoCodeSubmit'
							variant='contained'
							sx={{ borderRadius: '50px', py: '.5rem', px: '1.5rem' }}
							onClick={validatePromoCode}
						>
							{t('enter', { ns: 'booking' })}
						</Button>
					</div>
					{promoError && (
						<p className='text-red-600 text-xs'>
							{t('promotion-code-not-valid', { ns: 'booking' })}
						</p>
					)}
				</>
			)
		case 'loading':
			return <Loading />
		case 'valid':
			return <PromoCodeChip code={promoCode} onClick={removePromoCode} />
		default:
			return (
				<div
					data-cy='booking-promoCodeText'
					className='capitalize text-brand-blue font-bold underline cursor-pointer'
					onClick={() => setPromoCodeState('open')}
				>
					{t('enter-promo-code', { ns: 'booking' })}
				</div>
			)
	}
}

export default PromoCode
