import { Tag, X } from '@phosphor-icons/react'

const PromoCodeChip = ({ code, onClick, enableRemove = false, ...props }) => {
	return (
		<div
			data-cy='promoCodeChip'
			className={`flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-full w-min ${
				!enableRemove && 'cursor-pointer'
			} `}
		>
			<Tag size={20} />
			{code}
			{!enableRemove && <X size={20} onClick={onClick} />}
		</div>
	)
}

export default PromoCodeChip
