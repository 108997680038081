import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { Buildings, ClockClockwise, MapPin } from '@phosphor-icons/react'
import { TRANSLATIONS } from '../util/constants'

const SearchResults = ({
	handleMyLocationClick,
	handleSuggestionClick,
	variant
}) => {
	const { t } = useTranslation(TRANSLATIONS)
	const recentHistory = useSelector((state) => state.search.recentSearchHistory)
	const searchResultsData = useSelector(
		(state) => state.search.searchResultsData
	)

	return (
		<div
			className={`bg-white z-10 p-6 font-serif ${
				variant !== 'home' ? 'mt-20 lg:mt-0' : 'py-0'
			} `}
		>
			<Button
				fullWidth
				onClick={handleMyLocationClick}
				sx={{
					justifyContent: 'start',
					fontWeight: 'regular',
					mb: '12px',
					fontSize: '1rem',
					lineHeight: 1.5
				}}
				startIcon={<MapPin size={24} color='black' />}
			>
				<span className='text-black'>
					{t('my-current-location', { ns: 'common' })}
				</span>
			</Button>
			{recentHistory?.length > 0 && (
				<>
					<div className='flex px-1 py-2'>
						<ClockClockwise size={24} className='mr-2' />
						<div className='capitalize'>
							{t('recent-searches', { ns: 'common' })}
						</div>
					</div>
					{recentHistory?.map((element, index) => {
						if (element.zip_code) {
							return (
								<Button
									fullWidth
									key={index}
									className='my-2'
									onClick={() => handleSuggestionClick(element)}
									sx={{
										justifyContent: 'start',
										fontWeight: 'regular',
										mb: '12px',
										fontSize: '1rem',
										lineHeight: 1.5
									}}
								>
									<span className='flex flex-col justify-start items-start ml-7'>
										<span className='text-black'>{element.zip_code}</span>
										<span className='text-brand-graphite'>
											{element.state_abbr}
										</span>
									</span>
								</Button>
							)
						}

						if (element.city_name) {
							return (
								<Button
									fullWidth
									key={index}
									className='my-2'
									onClick={() => handleSuggestionClick(element)}
									sx={{
										justifyContent: 'start',
										fontWeight: 'regular',
										mb: '12px',
										fontSize: '1rem',
										lineHeight: 1.5
									}}
								>
									<span className='ml-7 text-black'>
										{element.city_name}, {element.state_abbr}
									</span>
								</Button>
							)
						}
					})}
				</>
			)}
			{searchResultsData?.zip_codes?.length > 0 && (
				<>
					<div className='flex px-1 py-2'>
						<MapPin size={24} className='mr-2' />
						<div className='capitalize'>{t('zip-code', { ns: 'booking' })}</div>
					</div>

					{searchResultsData.zip_codes.map((zip, index) => {
						return (
							<Button
								fullWidth
								key={index}
								className='my-2'
								onClick={() => handleSuggestionClick(zip)}
								sx={{
									justifyContent: 'start',
									fontWeight: 'regular',
									mb: '12px',
									fontSize: '1rem',
									lineHeight: 1.5
								}}
							>
								<span className='flex flex-col justify-start items-start ml-7'>
									<span className='text-black'>{zip.zip_code}</span>
									<span className='text-brand-graphite'>{zip.state_abbr}</span>
								</span>
							</Button>
						)
					})}
				</>
			)}
			{searchResultsData?.cities?.length > 0 && (
				<>
					<div className='flex px-1 py-2'>
						<Buildings size={24} className='mr-2' />
						<div className='col-span-11 mb-2 capitalize'>
							{t('city', { ns: 'booking' })}
						</div>
					</div>

					{searchResultsData.cities.map((city, index) => {
						return (
							<Button
								fullWidth
								key={index}
								className='my-2'
								onClick={() => handleSuggestionClick(city)}
								sx={{
									justifyContent: 'start',
									fontWeight: 'regular',
									mb: '12px',
									fontSize: '1rem',
									lineHeight: 1.5
								}}
							>
								<span className='ml-7 text-black'>
									{city.city_name}, {city.state_abbr}
								</span>
							</Button>
						)
					})}
				</>
			)}
			{searchResultsData?.cities?.length === 0 &&
				searchResultsData?.zip_codes?.length === 0 && (
					<div className='px-1 py-2'>
						<div className='col-span-12 my-4 '>
							{t('we-could-not-find-anything-for-this-location', {
								ns: 'common'
							})}
						</div>
					</div>
				)}
		</div>
	)
}

export default SearchResults
