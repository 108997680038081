import {
	Buildings,
	Storefront,
	HouseLine,
	Money,
	Lock,
	ArrowsOutLineVertical,
	LockLaminated,
	Chalkboard,
	Elevator,
	CarSimple,
	Package,
	CarProfile,
	GlobeSimple,
	Calendar,
	Webcam,
	Park,
	CallBell,
	Stool,
	TShirt,
	ClockClockwise,
	Thermometer,
	Door,
	List,
	UserCircle,
	Gauge,
	Key,
	Power,
	X,
	CaretUp,
	CaretDown,
	Info,
	FacebookLogo,
	InstagramLogo,
	TwitterLogo,
	Vault
} from '@phosphor-icons/react'

const FeatureIcon = ({
	iconName,
	size = 24,
	color,
	className,
	onClick,
	alt,
	style
}) => {
	let iconElement

	switch (iconName) {
		case 'Buildings':
			iconElement = (
				<Buildings
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Storefront':
			iconElement = (
				<Storefront
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'HouseLine':
			iconElement = (
				<HouseLine
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Money':
			iconElement = (
				<Money
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Lock':
			iconElement = (
				<Lock
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'ArrowsOutLineVertical':
			iconElement = (
				<ArrowsOutLineVertical
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'LockLaminated':
			iconElement = (
				<LockLaminated
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Chalkboard':
			iconElement = (
				<Chalkboard
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Elevator':
			iconElement = (
				<Elevator
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'CarSimple':
			iconElement = (
				<CarSimple
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Package':
			iconElement = (
				<Package
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'CarProfile':
			iconElement = (
				<CarProfile
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'GlobeSimple':
			iconElement = (
				<GlobeSimple
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Calendar':
			iconElement = (
				<Calendar
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Webcam':
			iconElement = (
				<Webcam
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Park':
			iconElement = (
				<Park
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'CallBell':
			iconElement = (
				<CallBell
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Stool':
			iconElement = (
				<Stool
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'TShirt':
			iconElement = (
				<TShirt
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'ClockClockwise':
			iconElement = (
				<ClockClockwise
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Thermometer':
			iconElement = (
				<Thermometer
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Door':
			iconElement = (
				<Door
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'List':
			iconElement = (
				<List
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'UserCircle':
			iconElement = (
				<UserCircle
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Gauge':
			iconElement = (
				<Gauge
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Key':
			iconElement = (
				<Key
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Power':
			iconElement = (
				<Power
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'X':
			iconElement = (
				<X
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'FacebookLogo':
			iconElement = (
				<FacebookLogo
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'InstagramLogo':
			iconElement = (
				<InstagramLogo
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'TwitterLogo':
			iconElement = (
				<TwitterLogo
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'FacebookLogo':
			iconElement = (
				<FacebookLogo
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'InstagramLogo':
			iconElement = (
				<InstagramLogo
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'TwitterLogo':
			iconElement = (
				<TwitterLogo
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'CaretUp':
			iconElement = (
				<CaretUp
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'CaretDown':
			iconElement = (
				<CaretDown
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Info':
			iconElement = (
				<Info
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		case 'Vault':
			iconElement = (
				<Vault
					size={size}
					color={color}
					className={className}
					onClick={onClick}
					alt={alt}
					style={style}
				/>
			)
			break
		default: // Return null for unknown icon names
			iconElement = null
			break
	}

	return iconElement
}

export default FeatureIcon
