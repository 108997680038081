import FeatureIcon from '../atoms/feature-icon'
import Link from 'next/link'
import StufLogo from '../public/stuf-logo-horizontal-white.svg'
import { TRANSLATIONS } from '../util/constants'
import { useTranslation } from 'react-i18next'

export function Footer({ phone, email, social, ...props }) {
	const { t } = useTranslation(TRANSLATIONS)

	const links = [
		{
			title: t('locations', { ns: 'common' }),
			href: '/locations'
		},
		{
			title: t('sizing', { ns: 'common' }),
			href: '/sizing'
		},
		{ title: t('faq', { ns: 'common' }), href: '/faq' },
		{
			title: t('contact', { ns: 'common' }),
			href: '/contact'
		},
		{
			title: t('self-storage-near-me', { ns: 'common' }),
			href: '/search-available-units'
		},
		{
			title: t('news', { ns: 'common' }),
			href: '/news'
		},
		{
			title: t('landlord', { ns: 'common' }),
			href: '/landlord'
		},
		{
			title: t('perks', { ns: 'common' }),
			href: '/perks'
		},
		{
			title: t('products', { ns: 'common' }),
			href: '/products'
		},
		{
			title: t('privacy-policy', { ns: 'booking' }),
			href: '/privacy-policy'
		},
		{
			title: t('terms-of-service', { ns: 'booking' }),
			href: '/terms-of-service'
		}
	]

	return (
		<footer
			className={`bg-brand-dark-blue pt-10 md:pt-10 pb-5 font-serif w-full ${props.className}`}
		>
			<div className='flex flex-col items-center md:flex-row md:justify-center max-w-full'>
				<div>
					<div className='flex justify-center md:justify-start mb-10'>
						<StufLogo width={133} height={35} />
					</div>
					<div className='space-y-1 flex flex-col items-center md:items-start'>
						<p className='font-bold text-white uppercase text-center md:text-left mb-3'>
							{t('reach-out-to-us', { ns: 'common' })}
						</p>
						<ul className='text-white space-y-3 text-center md:text-left'>
							<li>
								<a className='hover:underline' href={`tel:${phone}`}>
									{phone}
								</a>
							</li>
							<li>
								<a className='hover:underline' href={`mailto:${email}`}>
									{email}
								</a>
							</li>
						</ul>
						<div>
							<hr className='h-[3px] w-[50px] mt-7 mb-10 md:my-6 bg-brand-orange border-0 ' />
						</div>
						<ul className='flex space-x-4'>
							<li>
								<a
									rel='noopener noreferrer'
									target='_blank'
									href={`https://facebook.com/${social.facebook}`}
								>
									<FeatureIcon
										iconName={'FacebookLogo'}
										className={'text-brand-white'}
										size={32}
										alt={'Facebook Icon'}
									/>
								</a>
							</li>
							<li>
								<a
									rel='noopener noreferrer'
									target='_blank'
									href={`https://instagram.com/${social.instagram}`}
								>
									<FeatureIcon
										iconName={'InstagramLogo'}
										className={'text-brand-white'}
										size={32}
										alt={'Instagram Icon'}
									/>
								</a>
							</li>
							<li>
								<a
									rel='noopener noreferrer'
									target='_blank'
									href={`https://twitter.com/${social.twitter}`}
								>
									<FeatureIcon
										iconName={'TwitterLogo'}
										className={'text-brand-white'}
										size={32}
										alt={'Twitter Icon'}
									/>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className='md:ml-32 mt-10'>
					<nav className='grid grid-rows-6 grid-cols-2 grid-flow-col gap-x-4 md:gap-x-20 gap-y-4'>
						{links.map((link, index) => {
							return (
								<Link legacyBehavior key={index} href={link.href}>
									<a
										className={`block text-white hover:underline ${
											index < 6 ? 'text-right' : 'text-left'
										} md:text-left`}
									>
										{link.title}
									</a>
								</Link>
							)
						})}
					</nav>
				</div>
			</div>
			<hr className='h-[3px] w-[50px] mb-10 md:mb-5 mt-10 bg-brand-orange border-0 flex justify-center mx-auto' />
			<p
				className={`text-white text-center text-sm  ${
					props.showBottomNav ? 'mb-14' : 'mb-6'
				} lg:mb-0`}
			>
				{t('copyright', { year: new Date().getFullYear(), ns: 'common' })}
			</p>
		</footer>
	)
}
