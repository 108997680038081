import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import moment from 'moment/moment'

import { TRANSLATIONS, UNIT } from '../util/constants'
import date from '../util/date'
import facility from '../util/facility'
import menuOptions from '../util/menuOptions'
import unitUtil from '../util/unit'
import { getRemoteConfigValue } from '../util/firebase'

import { CalendarBlank, X } from '@phosphor-icons/react'

import { Button } from '../atoms/button'
import DateInput from '../atoms/date-input'
import DropdownInput from '../atoms/dropdown-input'

import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import LocationMapWithOverlayCards from './location-map-with-overlay-cards'

import {
	getNearbyLocations,
	getAvailableStorage,
	resetGetAvailableStorageStatus,
	resetGetNearbyLocationsStatus
} from '../slices/locationSlice'
import {
	getFacility,
	getUnit,
	setBookingInfo,
	postCalculateMoveInEstimate,
	resetGetFaciltyStatus,
	resetGetUnitStatus,
	getInsurance,
	setInsuranceId,
	resetGetInsuranceStatus,
	getUnitMembership,
	resetGetUnitMembershipStatus,
	setMembershipId,
	setHasInsuranceSelected
} from '../slices/bookingSlice'

const BookingEditModal = ({
	open,
	handleCloseBookingEditModal,
	setInsurance = true
}) => {
	const { t } = useTranslation(TRANSLATIONS)
	const theme = useTheme()
	const dispatch = useDispatch()

	const nearbyLocations = useSelector((state) => state.location.nearbyLocations)
	let availableStorage = useSelector((state) => state.location.availableStorage)
	let getAvailableStorageStatus = useSelector(
		(state) => state.location.getAvailableStorageStatus
	)
	let getNearbyLocationsStatus = useSelector(
		(state) => state.location.getNearbyLocationsStatus
	)
	const unitInfo = useSelector((state) => state.booking.unit) || [{}]

	const paymentInfo = useSelector((state) => state.booking.payment)

	const facilityInfo = useSelector((state) => state.booking.facility)

	const facilityId = facilityInfo?.facility_id

	const smallWindow = useMediaQuery('(min-width:768px)')

	const [updateDisabled, setUpdateDisabled] = useState(true)
	const [unitOptions, setUnitOptions] = useState(
		unitUtil.getUnitsOptions(availableStorage, t)
	)
	const [locationOptions, setLocationOptions] = useState(
		facility.getLocationsOptions(
			nearbyLocations,
			facilityInfo,
			availableStorage
		)
	)
	const [newMoveInDate, setNewMoveInDate] = useState(paymentInfo?.move_in_date)
	const [newFacilityId, setNewFacilityId] = useState(facilityId)
	const [selectedUnitValue, setSelectedUnitValue] = useState(
		(unitInfo.length > 0 &&
			unitOptions.find((item) => item.unitID === unitInfo?.[0].id)?.value) ||
			'0'
	)
	const [selectUnitDisabled, setSelectUnitDisabled] = useState(false)
	const [getFacilityAttempts, setGetFacilityAttempts] = useState(0)
	const [getUnitAttempts, setGetUnitAttempts] = useState(0)

	const getFacilityStatus = useSelector(
		(state) => state.booking.getFacilityStatus
	)

	const getUnitStatus = useSelector((state) => state.booking.getUnitStatus)
	const getInsuranceStatus = useSelector(
		(state) => state.booking.getInsuranceStatus
	)
	const insurance = useSelector((state) => state.booking.insurance)
	const unitMembership = useSelector((state) => state.booking.unitMembership)
	const getUnitMembershipStatus = useSelector(
		(state) => state.booking.getUnitMembershipStatus
	)

	const [showAvailableSoonUnits, setShowAvailableSoonUnits] = useState(false)

	const minDate = moment().add(1, 'day').format('M/D/YYYY')

	const handleFetchRemoteConfig = async () => {
		// Fetch location_available_soon_units from Firebase
		const enableAvailableSoonUnits = await getRemoteConfigValue(
			'location_available_soon_units'
		)

		// Bool value to know whether to show the available soon units or not
		setShowAvailableSoonUnits(
			enableAvailableSoonUnits === 'true' ? true : false
		)
	}

	useEffect(() => {
		// Fetch firebase remoteConfig on modal loading
		handleFetchRemoteConfig()
	}, [])

	const availableUnitsNearby = locationOptions?.length <= 1 ? false : true

	useEffect(() => {
		switch (getAvailableStorageStatus) {
			case 'PENDING':
				setSelectUnitDisabled(true)
				break
			case 'FULFILLED':
				setSelectUnitDisabled(false)

				setUnitOptions(unitUtil.getUnitsOptions(availableStorage, t))
				dispatch(resetGetAvailableStorageStatus())
				break
			case 'REJECTED':
				setSelectUnitDisabled(true)
				dispatch(resetGetAvailableStorageStatus())
				break
		}
	}, [getAvailableStorageStatus])

	useEffect(() => {
		switch (getNearbyLocationsStatus) {
			case 'FULFILLED':
				setLocationOptions(
					facility.getLocationsOptions(nearbyLocations, facilityInfo)
				)
				dispatch(resetGetNearbyLocationsStatus())
				break
			case 'REJECTED':
				dispatch(resetGetNearbyLocationsStatus())
				break
		}
	}, [getNearbyLocationsStatus])

	useEffect(() => {
		if (newFacilityId) {
			dispatch(getAvailableStorage(newFacilityId))
			dispatch(getNearbyLocations(facilityId))
		}
	}, [newFacilityId, facilityId])

	useEffect(() => {
		if (facilityInfo.unit_id !== unitInfo?.[0]?.id) {
			dispatch(getUnit(facilityInfo.unit_id))
		}
	}, [facilityInfo.unit_id])

	useEffect(() => {
		if (
			facilityInfo.facility_id &&
			paymentInfo.move_in_date &&
			unitInfo?.[0].price
		) {
			dispatch(
				postCalculateMoveInEstimate({
					body: {
						facility_id: facilityInfo.facility_id,
						monthly_price: unitInfo?.[0].price,
						move_in_date: paymentInfo.move_in_date,
						...(paymentInfo.insurance_id && {
							insurance_id: paymentInfo.insurance_id
						}),
						...(paymentInfo.membership_id && {
							membership_type_id: paymentInfo.membership_id
						}),
						...(paymentInfo.promo_id
							? { promo_id: paymentInfo.promo_id }
							: facilityInfo.discount_id !== null && {
									discount_id: facilityInfo.discount_id
							  })
					},
					fields: ['schedule']
				})
			)
		}
	}, [
		facilityInfo.facility_id,
		facilityInfo.discount_id,
		paymentInfo.move_in_date,
		paymentInfo.membership_id,
		unitInfo
	])

	useEffect(() => {
		switch (getFacilityStatus) {
			case 'FULFILLED':
				setGetFacilityAttempts(0)
				dispatch(resetGetFaciltyStatus())
				break
			case 'REJECTED':
				if (getFacilityAttempts < 4) {
					setGetFacilityAttempts((prevState) => prevState + 1)
					dispatch(getFacility(facilityInfo.facility_id))
				} else {
					// TODO error handling
					dispatch(resetGetFaciltyStatus())
				}
				break
			default:
				break
		}
	}, [getFacilityStatus])

	useEffect(() => {
		switch (getUnitStatus) {
			case 'FULFILLED':
				setGetUnitAttempts(0)
				dispatch(resetGetUnitStatus())
				dispatch(
					getUnitMembership({
						facilityId: facilityInfo.facility_id,
						unitId: facilityInfo.unit_id
					})
				)
				break
			case 'REJECTED':
				if (getUnitAttempts < 4) {
					setGetUnitAttempts((prevState) => prevState + 1)
					dispatch(getUnit(facilityInfo.unit_id))
				} else {
					// TODO error handling
					dispatch(resetGetFaciltyStatus())
				}
			default:
				break
		}
	}, [getUnitStatus])

	useEffect(() => {
		switch (getInsuranceStatus) {
			case 'PENDING':
				dispatch(resetGetInsuranceStatus())
				break
			case 'FULFILLED':
				if (insurance.length > 0 && !paymentInfo.insurance_id && setInsurance) {
					dispatch(setInsuranceId(2))
				}
				if (insurance.length === 0 && paymentInfo.insurance_id) {
					dispatch(setInsuranceId(null))
				}
				dispatch(resetGetInsuranceStatus())
				break
			case 'REJECTED':
				dispatch(resetGetInsuranceStatus())
				break
		}
	}, [getInsuranceStatus])

	useEffect(() => {
		switch (getUnitMembershipStatus) {
			case 'PENDING':
				dispatch(resetGetUnitMembershipStatus())
				break
			case 'FULFILLED':
				if (unitMembership.length === 0 && paymentInfo.membership_id) {
					dispatch(setMembershipId(null))
				}
				dispatch(resetGetUnitMembershipStatus())
				break
			case 'REJECTED':
				dispatch(resetGetUnitMembershipStatus())
				break
		}
	}, [getUnitMembershipStatus])

	useEffect(() => {
		setSelectedUnitValue(
			(unitInfo.length > 0 &&
				unitOptions.find((item) => item.unitID === unitInfo?.[0].id)?.value) ||
				'0'
		)
	}, [unitOptions, unitInfo])

	const customSelectInputProps = {
		labelProps: {
			fontWeight: 'normal',
			lineHeight: '17px',
			textTransform: 'capitalize',
			fontSize: '16px',
			sx: { mb: '4px' }
		},
		textFieldProps: {
			sx: { mb: '18px' },
			InputProps: {
				sx: {
					height: '40px',
					borderRadius: '6px',
					pr: '9px'
				}
			}
		},
		selectProps: {
			MenuProps: {
				PaperProps: {
					sx: {
						borderRadius: '15px',
						border: `solid 0.5px ${theme.palette.primary['medium-grey']}`,
						mt: '10px',
						minHeight: '100px',
						width: '258.4px'
					}
				}
			}
		}
	}

	const customDateInputProps = {
		labelProps: {
			fontWeight: 'normal',
			lineHeight: '17px',
			textTransform: 'capitalize',
			fontSize: '16px',
			sx: { mb: '4px' }
		},
		textFieldProps: {
			name: 'move-in-date',
			size: 'medium',
			value: date.formatDateToWeekdayMonthDay(newMoveInDate || minDate), //Date shown in the input textfield
			sx: {
				mb: 2
			},
			className: 'cursor-pointer',
			label: '',
			InputProps: {
				endAdornment: <CalendarBlank size={24} className='text-gray-400' />,
				sx: {
					borderRadius: '6px',
					height: '40px',
					fontSize: '16px'
				}
			},
			inputProps: {
				value: date.formatDateToWeekdayMonthDay(newMoveInDate || minDate)
			}
		},
		desktopDatePickerProps: {
			PaperProps: {
				sx: { borderRadius: '15px', cursor: 'pointer', mt: '6px' }
			},
			minDate: unitInfo?.[0]?.availability_date
				? moment(unitInfo?.[0]?.availability_date).format('M/D/YYYY')
				: minDate,
			maxDate: unitInfo?.[0]?.availability_date
				? moment(unitInfo?.[0]?.availability_date)
						.add(UNIT.BOOKING.NBR_DAYS_ADVANCE, 'days')
						.format('M/D/YYYY')
				: moment().add(UNIT.BOOKING.NBR_DAYS_ADVANCE, 'days').format('M/D/YYYY')
		}
	}

	useEffect(() => {
		const selectedUnit = unitOptions.find(
			(item) => item.value === selectedUnitValue
		)

		if (selectedUnit?.availableSoonUnit) {
			handleAvailableSoonUnit(selectedUnit)
		} else {
			handleRegularUnit()
		}

		handleUpdateButtonState(selectedUnit)
	}, [newMoveInDate, newFacilityId, selectedUnitValue])

	function handleAvailableSoonUnit(unit) {
		if (
			!date.isDateInRange(
				newMoveInDate,
				unit.availableDate,
				UNIT.BOOKING.NBR_DAYS_ADVANCE
			)
		) {
			const isReduxDateInRange = date.isDateInRange(
				paymentInfo?.move_in_date,
				unit.availableDate,
				UNIT.BOOKING.NBR_DAYS_ADVANCE
			)

			setNewMoveInDate(
				isReduxDateInRange
					? moment(paymentInfo?.move_in_date, 'M-D-YYYY').format('M/D/YYYY')
					: unit.availableDate
			)
		}
	}

	function handleRegularUnit() {
		if (
			!date.isDateInRange(newMoveInDate, minDate, UNIT.BOOKING.NBR_DAYS_ADVANCE)
		) {
			setNewMoveInDate(minDate)
		}
	}

	function handleUpdateButtonState(selectedUnit) {
		const isSameFacility = facilityId === newFacilityId
		const isSameMoveInDate = moment(newMoveInDate, 'M/D/YYYY').isSame(
			moment(paymentInfo?.move_in_date, 'M-D-YYYY')
		)
		const isSameUnit = selectedUnit?.unitID === unitInfo?.[0].id

		if (
			(isSameFacility && isSameMoveInDate && isSameUnit) ||
			selectedUnitValue === '0'
		) {
			setUpdateDisabled(true)
		} else {
			if (!selectedUnit) {
				setUpdateDisabled(true)
			} else {
				if (!isSameFacility && selectedUnit?.unitID === unitInfo?.[0].id) {
					setUpdateDisabled(true)
					setSelectedUnitValue('0')
				} else {
					setUpdateDisabled(false)
				}
			}
		}
	}

	function handleCancel() {
		handleCloseBookingEditModal()
		setNewMoveInDate(paymentInfo?.move_in_date)
		setNewFacilityId(facilityId)
		setSelectedUnitValue(
			unitOptions.find((item) => item.unitID === unitInfo[0].id)?.value || '0'
		)
	}

	function handleUpdate() {
		const unitID = unitOptions.find(
			(item) => item.value === selectedUnitValue
		).unitID

		dispatch(
			setBookingInfo({
				facilityId: newFacilityId,
				unitId: unitID,
				moveInDate: moment(newMoveInDate, 'M/D/YYYY').format('M-D-YYYY')
			})
		)

		if (facilityInfo.facility_id !== newFacilityId) {
			dispatch(getFacility(newFacilityId))
			dispatch(setHasInsuranceSelected(false))
			dispatch(getInsurance(newFacilityId))
		}
		handleCloseBookingEditModal()
		setUpdateDisabled(true)
	}

	return (
		<Dialog
			open={open}
			onClose={handleCancel}
			PaperProps={{
				sx: {
					py: '24px',
					margin: 0,
					borderRadius: '25px',
					maxWidth: '752px',
					minWidth: '351px',
					overflowX: 'hidden',
					overflowY: 'hidden'
				}
			}}
		>
			<div className='px-6'>
				<div className='flex flex-row justify-end'>
					<X size={24} onClick={handleCancel} className='cursor-pointer' />
				</div>
				<h2 className='font-sans text-2xl font-semibold leading-5 capitalize'>
					{t('update-booking', { ns: 'booking' })}
				</h2>
				<Divider className='mt-6' sx={{ my: 3 }} />
			</div>
			<div className=' bg-brand-white font-serif w-[351px] md:w-[752px] overflow-auto'>
				<div className='mb-1 md:w-[367px] px-6'>
					<DateInput
						disableCalendar={true}
						label={`${t('move-in-date', { ns: 'common' })}`}
						datePickerLabel={`${t('move-in-date', { ns: 'common' })}`}
						value={moment(newMoveInDate, 'M-D-YYYY').format('M/D/YYYY')}
						onChange={(value) => {
							setNewMoveInDate(moment(value).format('M/D/YYYY'))
						}}
						LabelProps={customDateInputProps.labelProps}
						TextFieldProps={customDateInputProps.textFieldProps}
						DesktopDatePickerProps={customDateInputProps.desktopDatePickerProps}
						MobileDatePickerProps={customDateInputProps.desktopDatePickerProps}
						type={!smallWindow ? 'mobile' : 'desktop'}
					/>
					<DropdownInput
						name={'facility_id'}
						readOnly={!availableUnitsNearby}
						label={`${t('location', { ns: 'common' })}`}
						value={newFacilityId}
						onChange={(value) => setNewFacilityId(value)}
						LabelProps={customSelectInputProps.labelProps}
						TextFieldProps={customSelectInputProps.textFieldProps}
						SelectProps={customSelectInputProps.selectProps}
						customMenuIcon
						customIconSize={24}
						customIconClassName={'text-gray-400'}
						customOptions={true}
						options={menuOptions.renderCustomOptions(
							locationOptions,
							{ push: () => {} },
							false,
							false,
							false,
							'100%',
							'text-base'
						)}
					/>
					<DropdownInput
						name={'selected_unit'}
						label={`${t('select-unit', { ns: 'common' })}`}
						value={selectedUnitValue}
						disabled={selectUnitDisabled}
						onChange={(value) => {
							setSelectedUnitValue(value)
						}}
						LabelProps={customSelectInputProps.labelProps}
						TextFieldProps={customSelectInputProps.textFieldProps}
						SelectProps={customSelectInputProps.selectProps}
						customMenuIcon
						customIconSize={24}
						customIconClassName={'text-gray-400'}
						customOptions
						options={menuOptions.renderCustomOptions(
							unitOptions,
							{ push: () => {} },
							showAvailableSoonUnits,
							true,
							true,
							'100%',
							'text-base'
						)}
					/>
				</div>
				<div>
					<LocationMapWithOverlayCards
						facilities={locationOptions.filter((location) => location.public)}
						variant={'booking-desktop'}
						selectedFacility={newFacilityId}
						setSelectedFacility={setNewFacilityId}
					/>
				</div>
			</div>
			<div className='flex items-center h-full gap-4 justify-end lg:justify-end px-6 mt-2'>
				<Button onClick={handleCancel} variant='text'>
					{t('cancel', {
						ns: 'common'
					})}
				</Button>
				<Button
					disabled={updateDisabled}
					onClick={handleUpdate}
					style={{ borderRadius: '100px' }}
				>
					{t('update', {
						ns: 'common'
					})}
				</Button>
			</div>
		</Dialog>
	)
}

export default BookingEditModal
