import { forwardRef } from 'react'
import cc from 'classcat'
import { Box } from '@mui/material'

export const CtaButton = forwardRef(
	({ as = 'button', size = 'sm', className, ...rest }, ref) => {
		const buttonClass = cc([
			'font-bold inline-flex text-center items-center text-md md:text-sm hover:cursor-pointer',
			className,
			{
				'py-1 px-2': size === 'xs',
				'py-1.5 px-3.5': size === 'sm',
				'py-3 px-6': size === 'base',
				'cursor-not-allowed opacity-50': rest.disabled
			}
		])

		return <Box component={as} ref={ref} className={buttonClass} {...rest} />
	}
)

CtaButton.displayName = 'Button'
