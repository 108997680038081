import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import cc from 'classcat'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { House, MagnifyingGlass, MapPin } from '@phosphor-icons/react'

import { Footer } from './footer'
import Header from './header'
import { HeaderWithoutNav } from './header-without-nav'
import { useRouter } from 'next/router'
import styles from '../styles/layout.module.css'
import { HEADER, companyInfo, TRANSLATIONS } from '../util/constants'

function Layout({
	children,
	site,
	ctaStyle = 'outline',
	i18n,
	headerType,
	headerVariant,
	stickyHeader = true,
	showBottomNav = true,
	booking = false,
	searchBarVisible = false,
	isMapLoaded,
	...props
}) {
	const { t } = useTranslation(TRANSLATIONS)
	const theme = useTheme()
	let isLoggedIn = useSelector((state) => state.root.isLoggedIn)
	const router = useRouter()

	const mainClass = cc([
		'flex flex-col min-h-screen',
		{ [styles.main]: !!headerType && !stickyHeader }
	])
	return (
		<div className={mainClass}>
			{headerType === HEADER.withNav && (
				<Header
					className={props.headerType === HEADER.withNav ? '' : 'hidden'}
					variant={headerVariant}
					stickyHeader={stickyHeader}
					phone={companyInfo.phone}
					ctaStyle={ctaStyle}
					isLoggedIn={isLoggedIn}
					router={router}
					booking={booking}
					searchBarVisible={searchBarVisible}
					isMapLoaded={isMapLoaded}
				/>
			)}
			{headerType === HEADER.withoutNav && (
				<HeaderWithoutNav
					className={props.headerType === HEADER.withoutNav ? '' : 'hidden'}
					phone={companyInfo.phone}
					bgColor={props.bgColor}
					isLoggedIn={isLoggedIn}
				/>
			)}
			<main className={`flex-1 ${props.extraClass || ''}`}>{children}</main>
			<Footer
				className={props.showFooter ? 'block' : 'hidden'}
				phone={companyInfo.phone}
				email={companyInfo.email}
				showBottomNav={showBottomNav}
				social={{
					twitter: companyInfo.social.twitter,
					facebook: companyInfo.social.facebook,
					instagram: companyInfo.social.instagram,
					appStoreUrl: companyInfo.social.appStoreUrl
				}}
			/>
			{showBottomNav && (
				<Paper
					className='z-40 lg:hidden '
					elevation={5}
					sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
				>
					<BottomNavigation>
						<BottomNavigationAction className='invisible' />
						<BottomNavigationAction
							onClick={() => router.push('/search-available-units')}
							showLabel={true}
							label={t('explore', { ns: 'common' })}
							icon={
								<MagnifyingGlass size={24} color={theme.palette.primary.main} />
							}
							sx={{ color: theme.palette.primary.main }}
						/>
						<BottomNavigationAction
							onClick={() => router.push('/locations')}
							showLabel={true}
							label={t('locations', { ns: 'common' })}
							icon={<MapPin size={24} color={theme.palette.primary.main} />}
							sx={{ color: theme.palette.primary.main }}
						/>
						<BottomNavigationAction
							onClick={() => router.push('/')}
							showLabel={true}
							label={t('home', { ns: 'common' })}
							icon={
								<House
									size={24}
									color={theme.palette.primary.main}
									weight='fill'
								/>
							}
							sx={{ color: theme.palette.primary.main }}
						/>
					</BottomNavigation>
				</Paper>
			)}
		</div>
	)
}

export default Layout
