import { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import FeatureIcon from './feature-icon'

const DropdownInput = ({
	label,
	value,
	name,
	options,
	onChange,
	error,
	helperText,
	LabelProps,
	TextFieldProps,
	SelectProps,
	fullWidth = true,
	size = 'small',
	disabled = false,
	id = '',
	open: initialOpen = false,
	customMenuIcon = false,
	customOpenIcon = 'CaretUp',
	customCloseIcon = 'CaretDown',
	customIconSize = 32,
	customIconClassName,
	hideCustomIcon = false,
	customOptions = false,
	readOnly = false,
	children
}) => {
	const [inputValue, setInputValue] = useState(value)
	const [open, setOpen] = useState(initialOpen)
	const handleChange = (e) => {
		setInputValue(e.target.value)

		onChange(e.target.value)
	}

	useEffect(() => {
		setInputValue(value)
	}, [options])

	const MenuIconComponent = () =>
		!hideCustomIcon && (
			<FeatureIcon
				size={customIconSize}
				iconName={open ? customOpenIcon : customCloseIcon}
				className={`flex absolute right-3 ${customIconClassName}`}
				style={{ pointerEvents: 'none' }}
			/>
		)

	const customIconSelectProps = (customMenuIcon) =>
		customMenuIcon
			? {
					...SelectProps,
					IconComponent: MenuIconComponent,
					onOpen: () => setOpen(true),
					onClose: () => setOpen(false)
			  }
			: {
					...SelectProps
			  }

	return (
		<>
			<Typography
				fontSize={14}
				fontWeight={'bold'}
				textTransform='uppercase'
				sx={{ mb: 1 }}
				{...LabelProps}
			>
				{label}
			</Typography>
			<TextField
				select
				id={id}
				disabled={disabled}
				fullWidth={fullWidth}
				size={size}
				label=''
				name={name}
				value={inputValue}
				onChange={handleChange}
				error={error}
				helperText={helperText}
				SelectProps={{
					...customIconSelectProps(customMenuIcon),
					readOnly: readOnly
				}}
				{...TextFieldProps}
			>
				{children}
				{!customOptions
					? options?.map((option, index) => (
							<MenuItem key={index} value={option.value}>
								{option.label}
							</MenuItem>
					  ))
					: options}
			</TextField>
		</>
	)
}

export default DropdownInput
