import cc from 'classcat'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { PERSON, TRANSLATIONS } from '../../util/constants'
import FeatureIcon from '../../atoms/feature-icon'

function HeaderMenuPopup({ isOpen, dashboardType, i18n, ...props }) {
	const { t } = useTranslation(TRANSLATIONS)
	const onboard_status = useSelector((state) => state.root.user.onboard_status)

	const items = [
		{
			title: t('my-dashboard', { ns: 'dashboard' }),
			onClick: () => {},
			...(onboard_status === PERSON.ONBOARD_STATUS.PENDING_APPROVAL
				? { href: '/booking/onboard' }
				: { href: `/dashboard/${dashboardType}` }),
			iconName: 'Gauge',
			mirror: true
		}
	]

	if (
		onboard_status === PERSON.ONBOARD_STATUS.ADMIN ||
		onboard_status === PERSON.ONBOARD_STATUS.ACTIVE_CONTRACT
	) {
		items.push({
			title: t('my-keys', { ns: 'dashboard' }),
			onClick: () => {},
			href: `/dashboard/${dashboardType}/digital-key/unlock`,
			iconName: 'Key',
			mirror: true
		})
	}

	items.push({
		title: t('log-out', { ns: 'common' }),
		onClick: props.handleLogout,
		href: '/',
		iconName: 'Power',
		mirror: false
	})

	return (
		<div
			className={cc([
				isOpen && 'lg:flex lg:absolute mt-1 drop-shadow-md',
				'bg-brand-white rounded-2xl z-20 hidden'
			])}
			style={{ height: 'auto', width: '200px', marginLeft: '-152px' }}
		>
			<div
				className='flex flex-col items-start justify-start'
				style={{ width: '100%' }}
			>
				{items.map((item, index) => {
					return (
						<Link legacyBehavior href={item.href} key={index}>
							<div
								className={`w-full hover:bg-brand-light-blue-1 ${
									index === items.length - 1 ? 'rounded-b-2xl' : ''
								} ${index === 0 ? 'rounded-t-2xl' : ''}`}
							>
								{item.title === 'Log out' && (
									<hr className='border-t-1 border-brand-cultured w-full' />
								)}
								<div className='flex items-center px-4'>
									<FeatureIcon
										iconName={item.iconName}
										className={`text-brand-charcoal-dark ${
											item.mirror ? 'transform scale-x-[-1]' : ''
										}`}
										size={32}
									/>
									<div
										onClick={item.onClick}
										className='font-base capitalize text-left text-brand-charcoal-dark ml-3 w-full py-2'
									>
										<p>{item.title}</p>
									</div>
								</div>
							</div>
						</Link>
					)
				})}
			</div>
		</div>
	)
}

export default HeaderMenuPopup
