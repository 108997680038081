import { InputAdornment } from '@mui/material'
import TextField from '@mui/material/TextField'
import { MagnifyingGlass } from '@phosphor-icons/react'

const SearchBar = ({
	value,
	setValue,
	onFocus,
	onBlur,
	onSubmit,
	id,
	variant,
	placeholder = 'By city or zip code'
}) => {
	return (
		<TextField
			value={value}
			onChange={(e) => setValue(e.target.value)}
			variant='outlined'
			fullWidth
			placeholder={placeholder}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start'>
						<MagnifyingGlass size={24} />
					</InputAdornment>
				),
				sx: {
					borderRadius: '999px',
					px: variant === 'home' ? '32px' : '16px',
					backgroundColor: 'white',
					...(variant === 'home' && { fontSize: '24px' })
				}
			}}
			inputProps={{
				style: {
					padding: variant === 'home' ? '20px 0' : '10px 0',
					...(variant === 'home' && { fontSize: '20px' })
				}
			}}
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyDown={onSubmit}
			id={id}
		></TextField>
	)
}

export default SearchBar
