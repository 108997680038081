import { Button as MUIButton } from '@mui/material'

export const Button = ({
	children,
	color = 'primary',
	type,
	style,
	variant = 'contained',
	onClick,
	disabled = false
}) => {
	return (
		<MUIButton
			color={color}
			variant={variant}
			type={type}
			sx={style}
			onClick={onClick}
			disabled={disabled}
		>
			{children}
		</MUIButton>
	)
}
