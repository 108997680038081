function TabPanel(props) {
	const { children, value, index, className, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <div className={className}>{children}</div>}
		</div>
	)
}

export default TabPanel
