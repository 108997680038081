import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import stringUtil from './string'

function renderCustomOptions(
	options,
	router = { push: () => {} },
	showAvailableSoonUnits = false,
	hideFirstOption = false,
	disableTooltip = false,
	optionTextMaxWidth,
	optionContainerClassName = '',
	navigateFromUnit
) {
	return options?.map((option, index) => {
		if (option.availableSoonUnit && !showAvailableSoonUnits) {
			return null
		}

		return (
			<MenuItem
				key={index}
				value={option.value}
				disabled={option.divider}
				sx={{
					display: `${option.value === '0' && hideFirstOption ? 'none' : ''}`,
					px: '12px',
					height: option.message && !option.divider ? '50px' : '40px',
					overflow: 'hidden',
					opacity: 100
				}}
				onClick={() =>
					!!navigateFromUnit
						? navigateFromUnit(option)
						: !!option.seo_location_url
						? router.push(
								`/${stringUtil.formatStringForUrl(
									option?.state_name
								)}-units-near-me/${option?.seo_location_url}`
						  )
						: {}
				}
				style={{ opacity: 1 }}
			>
				<Tooltip
					title={option.label}
					placement='left-start'
					disableHoverListener={disableTooltip}
					followCursor
				>
					<div
						className={`flex justify-center flex-col text-black overflow-hidden w-full h-full ${optionContainerClassName}`}
					>
						{option.label && (
							<p
								className='flex justify-between'
								style={{
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									maxWidth: optionTextMaxWidth
								}}
							>
								{`${option.label}`}

								{!!option.price && (
									<span>
										{!!option.priceDiscount && (
											<span className='mr-2 text-brand-light-grey-3 line-through'>
												{'$' + option.price}
											</span>
										)}
										<span>{`${
											!!option.priceDiscount
												? '$' + option.priceDiscount
												: '$' + option.price
										}`}</span>
									</span>
								)}
							</p>
						)}

						{option.divider ? (
							<>
								<hr className='flex-grow border-t' />
								<p className='mx-1 capitalize text-sm text-brand-graphite'>
									{option.message}
								</p>
							</>
						) : (
							<p className='text-xs text-brand-graphite'>{option.message}</p>
						)}
					</div>
				</Tooltip>
			</MenuItem>
		)
	})
}

export default { renderCustomOptions }
