import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { useTranslation } from 'next-i18next'
import { Info } from '@phosphor-icons/react'

import {
	postCalculateMoveInEstimate,
	setInsuranceId,
	setMembershipId,
	setHasInsuranceSelected
} from '../slices/bookingSlice'

import InsuranceInfo from '../atoms/insurance-info'
import { TRANSLATIONS } from '../util/constants'
import dateUtil from '../util/date'
import number from '../util/number'

const CalendarBreakdown = () => {
	const { t } = useTranslation(TRANSLATIONS)
	const theme = useTheme()
	const dispatch = useDispatch()
	const [scheduleIndex, setScheduleIndex] = useState(0)
	const [openTooltip, setOpenTooltip] = useState(false)
	const [openInsurance, setOpenInsurance] = useState(false)

	const paymentSchedule = useSelector(
		(state) => state.booking.payment.paymentSchedule
	)
	const unitInfo = useSelector((state) => state.booking.unit)
	const facilityInfo = useSelector((state) => state.booking.facility)
	const paymentInfo = useSelector((state) => state.booking.payment)
	const insurance = useSelector((state) => state.booking.insurance)
	const insurance_id = useSelector(
		(state) => state.booking.payment.insurance_id
	)

	const handleMonthChange = (month) => {
		const dueDate = moment(paymentSchedule[scheduleIndex].dueDate, 'M-D-YYYY')
		const monthDiff = month.month() - dueDate.month()

		// If the due date is after the 19th it includes the second month
		if (dueDate.date() > 19 && monthDiff === 1) {
			return
		}

		if (dateUtil.isAfterDate(paymentSchedule[scheduleIndex].dueDate, month)) {
			setScheduleIndex((prevState) => prevState + 1)
		} else {
			if (scheduleIndex === 0) return
			setScheduleIndex((prevState) => prevState - 1)
		}
	}

	const removeMembership = () => {
		dispatch(setMembershipId(null))
		dispatch(
			postCalculateMoveInEstimate({
				body: {
					facility_id: facilityInfo.facility_id,
					monthly_price: unitInfo?.[0].price,
					move_in_date: paymentInfo.move_in_date,
					...(value && { insurance_id: value }),
					...(paymentInfo.membership_id && {
						membership_type_id: paymentInfo.membership_id
					}),
					...(paymentInfo.promo_id
						? { promo_id: paymentInfo.promo_id }
						: facilityInfo.discount_id !== null && {
								discount_id: facilityInfo.discount_id
						  })
				},
				fields: ['schedule']
			})
		)
	}

	const setInsuranceValue = (value) => {
		dispatch(
			postCalculateMoveInEstimate({
				body: {
					facility_id: facilityInfo.facility_id,
					monthly_price: unitInfo?.[0].price,
					move_in_date: paymentInfo.move_in_date,
					...(value && { insurance_id: value }),
					...(paymentInfo.membership_id && {
						membership_type_id: paymentInfo.membership_id
					}),
					...(paymentInfo.promo_id
						? { promo_id: paymentInfo.promo_id }
						: facilityInfo.discount_id !== null && {
								discount_id: facilityInfo.discount_id
						  })
				},
				fields: ['schedule']
			})
		)
		dispatch(setInsuranceId(value))
		setOpenInsurance(false)
		dispatch(setHasInsuranceSelected(!!value))
	}

	const renderCustomDay = (date, _value, PickersDayProps) => {
		const formatDate = dateUtil.formatDateDashesMMDDYYYY(date)
		let calendarBreakdown = paymentSchedule[scheduleIndex].breakdown
		let isPMA = false
		let isDiscounted = false
		let isDueDate = paymentSchedule[scheduleIndex].dueDate === formatDate

		if (
			calendarBreakdown.discountStartDate &&
			calendarBreakdown.discountEndDate
		) {
			isDiscounted = dateUtil.isDateBetween(
				formatDate,
				calendarBreakdown.discountStartDate,
				calendarBreakdown.discountEndDate
			)
		}

		if (calendarBreakdown.pmaStartDate && calendarBreakdown.pmaEndDate) {
			isPMA = dateUtil.isDateBetween(
				formatDate,
				calendarBreakdown.pmaStartDate,
				calendarBreakdown.pmaEndDate
			)
		}

		if (isPMA) {
			return (
				<Badge
					key={date.toString()}
					overlap='circular'
					variant='dot'
					sx={{
						'& .MuiBadge-badge': {
							backgroundColor: theme.palette.primary.graphite,
							left: '15%',
							top: '85%',
							opacity: 0.2,
							height: '1px',
							width: 1,
							transform: 'rotate(-45deg)'
						}
					}}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
				>
					<PickersDay {...PickersDayProps} />
				</Badge>
			)
		}

		if (isDueDate && !isDiscounted) {
			return (
				<Badge
					overlap='circular'
					variant='dot'
					sx={{
						'& .MuiBadge-badge': {
							backgroundColor: theme.palette.primary.orange,
							left: '50%',
							zIndex: 2
						}
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
				>
					<PickersDay
						{...PickersDayProps}
						sx={{
							'&.Mui-disabled': {
								color: 'inherit'
							}
						}}
					/>
				</Badge>
			)
		}

		return (
			<Badge
				key={date.toString()}
				overlap='circular'
				variant='dot'
				invisible={!isDiscounted}
				sx={{
					'& .MuiBadge-badge': {
						backgroundColor: theme.palette.primary.blue,
						left: isDueDate ? '65%' : '50%',
						zIndex: 1
					}
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<Badge
					overlap='circular'
					variant='dot'
					invisible={!isDueDate}
					sx={{
						'& .MuiBadge-badge': {
							backgroundColor: theme.palette.primary.orange,
							left: isDiscounted ? '35%' : '50%',
							zIndex: 2
						}
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
				>
					<PickersDay
						{...PickersDayProps}
						sx={{
							'&.Mui-disabled': {
								color: 'inherit'
							}
						}}
					/>
				</Badge>
			</Badge>
		)
	}

	return (
		<>
			<div className='flex gap-2 ml-10'>
				<div className='flex border relative w-min gap-1 p-2 rounded-xl'>
					<span className='w-3 h-[1px] -rotate-45 bg-brand-graphite translate-y-1'></span>
					<span className='bg-brand-orange rounded-full w-2 h-2'></span>
					<span className='bg-brand-blue rounded-full w-2 h-2'></span>
				</div>
				<ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
					<Tooltip
						arrow
						placement='bottom'
						title={
							<div className='text-brand-eerie-black p-2'>
								<p className=' text-sm mb-3'>Calendar legend</p>
								<div className='flex gap-2 items-center mb-3'>
									<span className='w-3 h-[1px] -rotate-45 bg-brand-graphite translate-y-0.5'></span>
									Days of partial month adjustment
								</div>
								<div className='flex gap-3 items-center mb-3'>
									<span className='bg-brand-orange rounded-full w-2 h-2'></span>
									Payment due
								</div>
								<div className='flex gap-3 items-center'>
									<span className='bg-brand-blue rounded-full w-2 h-2'></span>
									Days included in discount
								</div>
							</div>
						}
						open={openTooltip}
						onClose={() => setOpenTooltip(false)}
						disableFocusListener
						disableHoverListener
						disableTouchListener
						componentsProps={{
							tooltip: {
								sx: {
									backgroundColor: theme.palette.primary['page-bg'],
									boxShadow: '4px 4px 4px 0px rgba(0, 76, 151, 0.04)'
								}
							},
							arrow: {
								sx: {
									color: theme.palette.primary['page-bg']
								}
							}
						}}
					>
						<Info
							size={24}
							className='text-brand-blue cursor-pointer'
							onClick={() => setOpenTooltip(true)}
						/>
					</Tooltip>
				</ClickAwayListener>
			</div>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<StaticDatePicker
					readOnly
					disabled
					disablePast
					value={null}
					disableHighlightToday
					displayStaticWrapperAs='desktop'
					maxDate={moment(
						dateUtil.addMonths(
							dateUtil.formatDateDashesMMDDYYYY(new Date()),
							5
						),
						'M-D-YYYY'
					)}
					renderDay={renderCustomDay}
					onChange={() => {}}
					renderInput={() => <div />}
					onMonthChange={handleMonthChange}
				/>
			</LocalizationProvider>

			<div className='border font-serif p-3 rounded-2xl'>
				<div className='flex justify-between text-brand-eerie-black'>
					<p className=''>
						{t('monthly-fee', {
							month: dateUtil.returnMonthFullName(
								dateUtil.formatDateDashesMMDDYYYYV2(
									paymentSchedule[scheduleIndex].dueDate
								)
							),
							ns: 'common'
						})}
					</p>
					<p className=''>
						{number.formatPrice(
							parseFloat(paymentSchedule[scheduleIndex].breakdown.monthlyPrice)
						)}
					</p>
				</div>
				{!!paymentSchedule[scheduleIndex].breakdown.insurancePremium && (
					<div className='flex justify-between items-center pl-4 mt-1'>
						<p className='text-brand-graphite text-sm'>
							{t('insurance', {
								ns: 'booking'
							})}
						</p>
						<div className='flex gap-3 items-center'>
							<Button
								sx={{ textDecoration: 'underline' }}
								onClick={() => setOpenInsurance(true)}
							>
								{t('change-plan', { ns: 'booking' })}
							</Button>
							<p className=''>
								{number.formatPrice(
									paymentSchedule[scheduleIndex].breakdown.insurancePremium
								)}
							</p>
						</div>
					</div>
				)}
				{!!paymentSchedule[scheduleIndex].breakdown.membershipPrice && (
					<div className='flex justify-between items-center pl-4 mt-1'>
						<p className='text-brand-graphite text-sm'>
							{t('stuf-blue', {
								ns: 'booking'
							})}
						</p>
						<div className='flex items-center gap-3'>
							<Button
								sx={{ textDecoration: 'underline' }}
								onClick={removeMembership}
							>
								{t('remove', { ns: 'booking' })}
							</Button>
							<p className=''>
								{number.formatPrice(
									paymentSchedule[scheduleIndex].breakdown.membershipPrice
								)}
							</p>
						</div>
					</div>
				)}
				{paymentSchedule[scheduleIndex].breakdown.pma > 0 && (
					<div className='flex justify-between pl-4 mt-2'>
						<div>
							<p className='text-brand-blue text-sm'>
								{t('partial-month-adjustment', {
									ns: 'booking'
								})}
							</p>
							<p className='text-brand-graphite text-xs'>
								{dateUtil.returnMonthAndDay(
									dateUtil.returnFirstDateOfMonth(
										dateUtil.formatDateDashesMMDDYYYYV2(
											paymentSchedule[scheduleIndex].dueDate
										)
									)
								)}
								{' - '}
								{dateUtil.returnMonthAndDay(
									dateUtil.formatDateDashesMMDDYYYYV2(
										paymentSchedule[scheduleIndex].dueDate
									)
								)}{' '}
								({paymentSchedule[scheduleIndex].breakdown.pmaDailyRate})
							</p>
						</div>

						<p className='text-brand-blue font-bold'>
							-
							{number.formatPrice(paymentSchedule[scheduleIndex].breakdown.pma)}
						</p>
					</div>
				)}
				{moment(paymentSchedule[scheduleIndex].dueDate, 'M-D-YYYY').date() >
					19 && (
					<>
						<div className='flex justify-between text-brand-eerie-black mt-4'>
							<p className=''>
								{t('monthly-fee', {
									month: dateUtil.returnMonthFullName(
										dateUtil.addMonths(
											dateUtil.formatDateDashesMMDDYYYYV2(
												paymentSchedule[scheduleIndex].dueDate
											),
											1
										)
									),
									ns: 'common'
								})}
							</p>
							<p className=''>
								{number.formatPrice(
									parseFloat(
										paymentSchedule[scheduleIndex].breakdown.monthlyPrice
									)
								)}
							</p>
						</div>
						{!!paymentSchedule[scheduleIndex].breakdown.insurancePremium && (
							<div className='flex justify-between items-center pl-4 mt-1'>
								<p className='text-brand-graphite text-sm'>
									{t('insurance', {
										ns: 'booking'
									})}
								</p>
								<p className=''>
									{number.formatPrice(
										paymentSchedule[scheduleIndex].breakdown.insurancePremium
									)}
								</p>
							</div>
						)}
						{!!paymentSchedule[scheduleIndex].breakdown.membershipPrice && (
							<div className='flex justify-between items-center pl-4 mt-1'>
								<p className='text-brand-graphite text-sm'>
									{t('stuf-blue', {
										ns: 'booking'
									})}
								</p>
								<p className=''>
									{number.formatPrice(
										paymentSchedule[scheduleIndex + 1].breakdown.membershipPrice
									)}
								</p>
							</div>
						)}
					</>
				)}
				{paymentSchedule[scheduleIndex].breakdown.credits > 0 && (
					<div className='flex justify-between text-brand-eerie-black mt-4'>
						<div>
							<p className='text-brand-blue'>
								{t('discounts-applied', {
									ns: 'booking'
								})}
							</p>
							<p className='text-xs text-brand-graphite'>
								{!!paymentSchedule[scheduleIndex].breakdown.promoText
									? `${t('promo-code', { ns: 'booking' })} - ${
											paymentSchedule[scheduleIndex].breakdown.promoText
									  }`
									: !!paymentSchedule[scheduleIndex].breakdown.discountText
									? `${t('welcome-discount', {
											ns: 'booking'
									  })} - ${
											paymentSchedule[scheduleIndex].breakdown.discountText
									  }`
									: ''}
							</p>
						</div>

						<p className='text-brand-blue font-bold'>
							-
							{number.formatPrice(
								paymentSchedule[scheduleIndex].breakdown.credits
							)}
						</p>
					</div>
				)}
				<div className='flex justify-between mt-4'>
					<p className='font-bold capitalize'>
						{t('due', { ns: 'booking' })}{' '}
						{dateUtil.returnMonthAbbrAndDay(
							paymentSchedule[scheduleIndex].dueDate
						)}
					</p>
					<p className='font-bold'>
						{number.formatPrice(
							paymentSchedule[scheduleIndex].breakdown.totalDue
						)}
					</p>
				</div>
			</div>
			<div className='font-serif text-brand-graphite text-xs p-6'>
				{t('payment-schedule-disclosure', { ns: 'booking' })}
			</div>
			<Dialog
				open={openInsurance}
				onClose={() => setOpenInsurance(false)}
				PaperProps={{
					sx: {
						borderRadius: '25px'
					}
				}}
			>
				<div className='p-4'>
					<RadioGroup name='insurance' value={insurance_id}>
						{insurance.map((e, index) => {
							return (
								<div
									key={index}
									className={`border-2 rounded-xl font-serif cursor-pointer mb-4 ${
										insurance_id === e.id
											? 'border-brand-blue'
											: 'lg:border-white'
									}`}
									value={e.id}
									onClick={() => setInsuranceValue(e.id)}
									data-cy={`insurance-option-${e.id}`}
								>
									<InsuranceInfo insurance={e} />
								</div>
							)
						})}
						<FormControlLabel
							control={<Radio checked={insurance_id === null} />}
							value={null}
							label={t('i-have-my-own-coverage', {
								ns: 'booking'
							})}
							onClick={() => setInsuranceValue(null)}
							sx={{ ml: '12px' }}
							data-cy='insurance-option-null'
						/>
					</RadioGroup>
				</div>
			</Dialog>
		</>
	)
}

export default CalendarBreakdown
