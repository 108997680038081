import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { ArrowSquareOut, Calendar, ListDashes, X } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import {
	postCalculateMoveInEstimate,
	setHasInsuranceSelected,
	resetBookingState,
	getFacility,
	getUnit,
	setInsuranceId,
	setBookingMoveinDate,
	resetGetInsuranceStatus,
	resetGetFaciltyStatus,
	resetGetUnitStatus,
	resetPostCalculateMoveInEstimateStatus,
	setCartOpen
} from '../slices/bookingSlice'

import { Loading } from '../atoms/loading'
import TabPanel from '../atoms/tab-panel'
import PromoCode from '../molecules/booking-promo-code'
import PricingBreakdown from '../molecules/pricing-breakdown'
import BookingEditModal from '../organisms/booking-edit-modal'
import { TRANSLATIONS } from '../util/constants'
import dateUtil from '../util/date'
import unitUtil from '../util/unit'
import { Dialog } from '@mui/material'
import CalendarBreakdown from './calendar-breakdown'

const Cart = () => {
	const { t } = useTranslation(TRANSLATIONS)
	const dispatch = useDispatch()
	const router = useRouter()

	const [tabValue, setTabValue] = useState(0)
	const [openEditModal, setOpenEditModal] = useState(false)
	const [openRemoveUnit, setOpenRemoveUnit] = useState(false)
	const [loading, setLoading] = useState(true)
	const [getFacilityAttempts, setGetFacilityAttempts] = useState(0)
	const [getUnitAttempts, setGetUnitAttempts] = useState(0)
	const [calculatePaymentAttempts, setCalculatePaymentAttempts] = useState(0)
	const cartOpen = useSelector((state) => state.booking.cartOpen)
	const unitInfo = useSelector((state) => state.booking.unit)
	const facilityInfo = useSelector((state) => state.booking.facility)
	const paymentInfo = useSelector((state) => state.booking.payment)
	const hasInsuranceSelected = useSelector(
		(state) => state.booking.hasInsuranceSelected
	)
	const insurance = useSelector((state) => state.booking.insurance)
	const getFacilityStatus = useSelector(
		(state) => state.booking.getFacilityStatus
	)
	const getUnitStatus = useSelector((state) => state.booking.getUnitStatus)
	const getInsuranceStatus = useSelector(
		(state) => state.booking.getInsuranceStatus
	)
	const postCalculateMoveInEstimateStatus = useSelector(
		(state) => state.booking.postCalculateMoveInEstimateStatus
	)

	// Get facility and unit information should be called from BookNowCTA
	useEffect(() => {
		switch (getFacilityStatus) {
			case 'FULFILLED':
				setGetFacilityAttempts(0)
				dispatch(resetGetFaciltyStatus())
				break
			case 'REJECTED':
				if (getFacilityAttempts < 4) {
					setGetFacilityAttempts((prevState) => prevState + 1)
					dispatch(getFacility(facilityInfo.facility_id))
				} else {
					// TODO error handling
					dispatch(resetGetFaciltyStatus())
				}
				break
			default:
				break
		}
	}, [getFacilityStatus])

	useEffect(() => {
		switch (getUnitStatus) {
			case 'FULFILLED':
				setGetUnitAttempts(0)
				dispatch(resetGetUnitStatus())
				break
			case 'REJECTED':
				if (getUnitAttempts < 4) {
					setGetUnitAttempts((prevState) => prevState + 1)
					dispatch(getUnit(facilityInfo.unit_id))
				} else {
					// TODO error handling
					dispatch(resetGetFaciltyStatus())
				}
			default:
				break
		}
	}, [getUnitStatus])

	useEffect(() => {
		switch (postCalculateMoveInEstimateStatus) {
			case 'FULFILLED':
				setCalculatePaymentAttempts(0)
				dispatch(resetPostCalculateMoveInEstimateStatus())
				setLoading(false)
				break
			case 'REJECTED':
				if (calculatePaymentAttempts < 4) {
					setCalculatePaymentAttempts((prevState) => prevState + 1)
					dispatch(
						postCalculateMoveInEstimate({
							body: {
								facility_id: facilityInfo.facility_id,
								monthly_price: unitInfo?.[0].price,
								move_in_date: paymentInfo.move_in_date,
								...(paymentInfo.promo_id
									? { promo_id: paymentInfo.promo_id }
									: facilityInfo.discount_id !== null && {
											discount_id: facilityInfo.discount_id
									  }),
								...(paymentInfo.insurance_id && {
									insurance_id: paymentInfo.insurance_id
								}),
								...(paymentInfo.membership_id && {
									membership_type_id: paymentInfo.membership_id
								})
							},
							fields: ['schedule']
						})
					)
				} else {
					// TODO error handling
					dispatch(resetPostCalculateMoveInEstimateStatus())
				}
			default:
				break
		}
	}, [postCalculateMoveInEstimateStatus])

	useEffect(() => {
		if (
			facilityInfo.facility_id &&
			paymentInfo.move_in_date &&
			unitInfo?.[0].price
		) {
			dispatch(
				postCalculateMoveInEstimate({
					body: {
						facility_id: facilityInfo.facility_id,
						monthly_price: unitInfo?.[0].price,
						move_in_date: paymentInfo.move_in_date,
						...(paymentInfo.promo_id
							? { promo_id: paymentInfo.promo_id }
							: facilityInfo.discount_id !== null && {
									discount_id: facilityInfo.discount_id
							  }),
						...(paymentInfo.insurance_id && {
							insurance_id: paymentInfo.insurance_id
						}),
						...(paymentInfo.membership_id && {
							membership_type_id: paymentInfo.membership_id
						})
					},
					fields: ['schedule']
				})
			)
		}
	}, [
		facilityInfo.facility_id,
		facilityInfo.discount_id,
		paymentInfo.move_in_date,
		unitInfo
	])

	useEffect(() => {
		if (
			!paymentInfo?.move_in_date ||
			dateUtil.hasDatePassed(paymentInfo?.move_in_date)
		) {
			dispatch(
				setBookingMoveinDate(
					dateUtil.formatDateDashesMMDDYYYY(dateUtil.returnFutureDate(1))
				)
			)
		}
	}, [paymentInfo.move_in_date])

	useEffect(() => {
		switch (getInsuranceStatus) {
			case 'PENDING':
				dispatch(resetGetInsuranceStatus())
				break
			case 'FULFILLED':
				let insuranceId = null
				if (insurance.length > 0 && !hasInsuranceSelected) {
					insuranceId = 2
					dispatch(setInsuranceId(2))
					dispatch(setHasInsuranceSelected(true))
				} else {
					insuranceId = paymentInfo.insurance_id
				}
				dispatch(resetGetInsuranceStatus())
				break
			case 'REJECTED':
				dispatch(resetGetInsuranceStatus())
				break
		}
	}, [getInsuranceStatus])

	const removeFromCart = () => {
		dispatch(setCartOpen(false))
		dispatch(resetBookingState())
		setLoading(true)
		setOpenRemoveUnit(false)
	}

	const navigateToBooking = () => {
		dispatch(setCartOpen(false))
		router.push('/booking/complete-booking')
	}

	const unitSize = unitUtil.getSizeCategoryFromId(
		unitInfo.length > 0 ? unitInfo?.[0].size_category : ''
	)

	const unitLabel = `${t(unitSize, { ns: 'common' })} (${
		unitInfo.length > 0 && unitInfo?.[0].width
	} x ${unitInfo.length > 0 && unitInfo?.[0].length})`

	return (
		<Drawer
			anchor='right'
			open={cartOpen}
			onClose={() => dispatch(setCartOpen(false))}
			sx={{
				minWidth: '300px'
			}}
		>
			<div className='max-h-screen sm:max-w-md w-screen sm:w-[448px] flex flex-col p-4 sm:p-6 relative'>
				<div className='flex justify-between items-center' data-cy='cart'>
					<h2 className='text-2xl font-bold'>{t('cart', { ns: 'booking' })}</h2>
					<IconButton onClick={() => dispatch(setCartOpen(false))}>
						<X />
					</IconButton>
				</div>
				{loading && (
					<p className='flex justify-center items-center h-screen'>
						<Loading />
					</p>
				)}
				{!loading && (
					<>
						<Tabs value={tabValue} onChange={(e, value) => setTabValue(value)}>
							<Tab
								label='Pricing Details'
								icon={<ListDashes size={24} />}
								iconPosition='start'
							/>
							<Tab
								label='Payment Schedule'
								icon={<Calendar size={24} />}
								iconPosition='start'
							/>
						</Tabs>
						<div className='flex-1 h-full overflow-y-scroll mb-12'>
							<TabPanel value={tabValue} index={0} className='px-4'>
								<div className='font-serif mt-4'>
									<div className='flex items-center'>
										<div className='flex-1'>
											<h3 className='text-lg font-semibold capitalize'>
												{t('unit', { ns: 'common' })}: {unitLabel}
											</h3>
											{unitInfo[0]?.tags?.length > 0 && (
												<p className='text-sm text-brand-graphite'>
													{unitInfo[0]?.tags.map((tag) => tag.name).join(', ')}
												</p>
											)}
										</div>
										<Button
											sx={{ textDecoration: 'underline' }}
											onClick={() => setOpenRemoveUnit(true)}
										>
											{t('remove', { ns: 'booking' })}
										</Button>
										<Button
											sx={{ textDecoration: 'underline' }}
											onClick={() => setOpenEditModal(true)}
										>
											{t('edit', { ns: 'common' })}
										</Button>
									</div>
									<h4 className='mt-4 text-sm text-brand-graphite'>
										{t('move-in-date', { ns: 'common' })}
									</h4>
									<p className='text-brand-eerie-black'>
										{paymentInfo.move_in_date &&
											dateUtil.formatDateToWeekdayMonthDay(
												paymentInfo.move_in_date
											)}
									</p>
									<h4 className='mt-4 text-sm text-brand-graphite'>
										{t('location', { ns: 'common' })}
									</h4>
									<p className='flex justify-between overflow-ellipsis overflow-hidden text-brand-eerie-black'>
										{[
											facilityInfo.address,
											facilityInfo?.city_name,
											`${facilityInfo?.state_abbr} ${facilityInfo?.zip}`
										]
											.filter((e) => e !== undefined)
											.join(', ')}
										{facilityInfo.public && facilityInfo.google_url && (
											<a
												href={facilityInfo.google_url}
												target='_blank'
												rel='noopener noreferrer'
												className='cursor-pointer'
											>
												<ArrowSquareOut size={24} />
											</a>
										)}
									</p>

									<BookingEditModal
										open={openEditModal}
										handleCloseBookingEditModal={() => setOpenEditModal(false)}
										setInsurance={!hasInsuranceSelected}
									/>
								</div>
								<div className='my-4 font-serif'>
									<PromoCode />
								</div>
								<Divider sx={{ mb: '16px' }} />
								<PricingBreakdown
									pricingDetails={{
										monthlyPrice: unitInfo[0]?.price,
										after19thMoveInPayment:
											paymentInfo.after_19th_move_in_payment,
										firstMonthPayment: paymentInfo.current_month_payment,
										firstMonthRate: paymentInfo.first_month_rate,
										firstMonthPMA: paymentInfo.first_month_pma,
										total: paymentInfo.total_due_on_move_in,
										moveInCredits: paymentInfo.move_in_credit,
										moveInDate: paymentInfo.move_in_date,
										after19thInsurance:
											paymentInfo.after_19th_insurance_premium,
										firstMonthInsurance:
											paymentInfo.first_month_insurance_premium,
										firstMonthMembership: paymentInfo.first_Month_Membership,
										after19thMembership: paymentInfo.after_19th_Membership,
										insurancePremium: paymentInfo.insurancePremium,
										membershipPrice: paymentInfo.membershipPrice,
										discountText: facilityInfo.discount_banner_text,
										promoText: paymentInfo.promo_banner_text
									}}
								/>
							</TabPanel>
							<TabPanel value={tabValue} index={1} className='px-4'>
								<CalendarBreakdown />
							</TabPanel>
						</div>
						<div className='w-full relative mt-auto flex flex-col '>
							<div className='fixed bottom-4 inset-x-10 sm:max-w-sm sm:right-8 sm:inset-x-auto sm:w-96'>
								<Button
									fullWidth
									variant='contained'
									sx={{ borderRadius: '99px' }}
									onClick={navigateToBooking}
									disabled={!unitInfo[0]?.id}
									data-cy='bookNowCart'
								>
									{t('book-now', { ns: 'common' })}
								</Button>
							</div>
						</div>
					</>
				)}
			</div>
			<Dialog
				open={openRemoveUnit}
				onClose={() => setOpenRemoveUnit(false)}
				PaperProps={{ sx: { borderRadius: '24px' } }}
				maxWidth='xs'
			>
				<div className='p-6'>
					<div className='flex justify-end'>
						<IconButton onClick={() => setOpenRemoveUnit(false)}>
							<X />
						</IconButton>
					</div>
					<div className='font-semibold text-2xl mb-6'>
						{t('are-you-sure-you-want-to-remove-this-unit', {
							ns: 'booking'
						})}
					</div>
					<Button
						onClick={() => setOpenRemoveUnit(false)}
						fullWidth
						variant='contained'
						sx={{
							borderRadius: '99px',
							mb: '8px',
							textTransform: 'capitalize'
						}}
					>
						{t('nevermind', { ns: 'dashboard' })}
					</Button>
					<Button onClick={removeFromCart} fullWidth>
						{t('yes-remove', { ns: 'booking' })}
					</Button>
				</div>
			</Dialog>
		</Drawer>
	)
}

export default Cart
