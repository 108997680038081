import { useTheme } from '@mui/material'
import { Shield, ShieldCheck, ShieldStar } from '@phosphor-icons/react'

const InsuranceIcon = ({ id }) => {
	const theme = useTheme()
	switch (id) {
		case 1:
			return <Shield size={24} color={theme.palette.primary['dark-blue']} />
		case 3:
			return <ShieldStar size={24} color={theme.palette.primary['dark-blue']} />
		case 2:
		default:
			return (
				<ShieldCheck size={24} color={theme.palette.primary['dark-blue']} />
			)
	}
}

export default InsuranceIcon
