import Chip from '@mui/material/Chip'
import Rating from '@mui/material/Rating'
import { useTheme } from '@mui/material'

import { ArrowSquareOut, Tag } from '@phosphor-icons/react'

const MapOverlayCard = ({ facility }) => {
	const theme = useTheme()

	return (
		<div className='flex flex-col p-3 w-[327px] h-[140px] rounded-xl bg-white mb-1'>
			<div className='flex '>
				<div className='flex flex-col flex-grow leading-4'>
					<p className='font-serif font-semibold'>{facility.business_name}</p>
					<h4 className='font-serif text-brand-graphite mt-2'>
						{facility.address}
					</h4>
				</div>
				<div className='h-[24px]'>
					<a
						href={facility.locationURL}
						target='_blank'
						rel='noopener noreferrer'
					>
						<ArrowSquareOut size={24} />
					</a>
				</div>
			</div>
			<div className='mt-5'>
				<div
					className={`flex items-center font-serif  ${
						facility.discount_banner_text ? '' : 'hidden'
					}`}
				>
					<Tag
						size={24}
						color={theme.palette.primary.main}
						className='mr-2'
						weight='fill'
					/>

					<p className='text-sm'>{facility.discount_banner_text}</p>
				</div>
				<div className='flex flex-row items-center mt-2'>
					{Boolean(facility.marketing_tag) && (
						<Chip
							sx={{
								border: '1px solid black',
								background: 'none',
								height: '24px',
								marginRight: '8px'
							}}
							label={facility.marketing_tag}
						/>
					)}

					{!isNaN(facility.average_rating) && facility.average_rating >= 4 && (
						<>
							<Rating
								readOnly
								name='single-star'
								value={3}
								max={1}
								size='medium'
								sx={{
									display: 'flex',
									color: theme.palette.primary.main
								}}
							/>
							<p className='font-serif font-semibold ml-2'>
								{Number(facility?.average_rating).toFixed(1)}
							</p>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default MapOverlayCard
