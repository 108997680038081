import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

import MapOverlayCard from './map-overlay-card'

const SwipeableMapOverlayCards = ({
	selectedFacility,
	setSelectedFacility,
	facilityCards,
	facilities,
	cityMapData,
	smallWindow
}) => {
	const [swiper, setSwiper] = useState(null)
	const [activeCard, setActiveCard] = useState(selectedFacility)

	useEffect(() => {
		if (selectedFacility !== activeCard) {
			setSelectedFacility(activeCard)
		}
	}, [activeCard])

	useEffect(() => {
		if (selectedFacility !== activeCard) {
			setActiveCard(selectedFacility)
		}
		const facility = facilities.find(
			(facility) => facility.id === selectedFacility
		)

		const slideIndex = cityMapData.findIndex(
			(location) => location.displayAddress === facility?.address
		)
		if (swiper && swiper.realIndex !== slideIndex) {
			swiper?.slideTo(slideIndex)
		}
	}, [selectedFacility])

	useEffect(() => {
		if (swiper) {
			swiper.allowTouchMove = smallWindow
		}
	}, [smallWindow, swiper])

	return (
		<Swiper
			slidesPerView={'auto'}
			spaceBetween={12}
			freeMode={false}
			loop
			onSwiper={setSwiper}
			onSlideChangeTransitionEnd={(swiper) => {
				const facilityId = facilities.find(
					(facility) =>
						facility.address === cityMapData[swiper?.realIndex]?.displayAddress
				)?.id

				setActiveCard(facilityId)
			}}
			style={{ display: 'flex', maxHeight: '140px', height: 'auto' }}
		>
			{facilityCards &&
				facilityCards?.map((facility, i) => {
					return (
						<SwiperSlide key={i} style={{ width: 'auto' }}>
							<MapOverlayCard facility={facility} />
						</SwiperSlide>
					)
				})}
		</Swiper>
	)
}

export default SwipeableMapOverlayCards
