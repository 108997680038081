import '../styles/header.module.css'
import Image from 'next/image'
import cc from 'classcat'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { CaretDown, Phone, ShoppingCart } from '@phosphor-icons/react'

import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import { useMediaQuery, useTheme } from '@mui/material'

import { CtaButton } from './cta-button'
import HeaderMenuPopup from './form/header-menu-popup'
import { logoutUser } from '../slices/rootSlice'
import { setCartOpen } from '../slices/bookingSlice'
import NavbarSearchBar from '../molecules/navbar-search-bar'
import { Link } from '../atoms/link'
import FeatureIcon from '../atoms/feature-icon'
import { Text } from '../atoms/text'
import { companyInfo, PERSON, TRANSLATIONS } from '../util/constants'
import Cart from '../organisms/cart'

function Header({
	phone,
	ctaStyle,
	isLoggedIn,
	router,
	variant = 'white',
	stickyHeader = true,
	booking = false,
	searchBarVisible = false,
	isMapLoaded
}) {
	const { t } = useTranslation(TRANSLATIONS)
	const dispatch = useDispatch()
	const lg = useMediaQuery('(min-width: 1024px')
	const theme = useTheme()
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	let user = useSelector((state) => state.root.user)
	const onboard_status = useSelector((state) => state.root.user.onboard_status)
	const unit = useSelector((state) => state.booking.unit)

	const bookNowStyle = cc([
		{
			'bg-brand-coral text-white border-brand-blue rounded-full border-2 text-xs lg:text-xs xl:text-base truncate':
				ctaStyle === 'primary' && variant !== 'white',
			'bg-white border-brand-white text-brand-blue rounded-full border-2 text-xs lg:text-xs xl:text-base truncate':
				ctaStyle === 'outline' && variant !== 'white',
			'bg-white border-brand-blue text-brand-blue rounded-full border-2 text-xs lg:text-xs xl:text-base truncate':
				ctaStyle === 'outline' && variant === 'white'
		}
	])

	const headerLinks = [
		...(!searchBarVisible
			? [
					{
						title: t('search', { ns: 'common' }),
						href: '/search-available-units'
					}
			  ]
			: []),
		{
			title: t('locations', { ns: 'common' }),
			href: '/locations'
		},
		{
			title: t('sizing', { ns: 'common' }),
			href: '/sizing'
		},
		...(variant !== 'alternate-white'
			? [
					{
						title: t('contact', { ns: 'common' }),
						href: '/contact'
					}
			  ]
			: []),
		{
			title: t('landlord', { ns: 'common' }),
			href: '/landlord'
		},
		{
			title: t('products', { ns: 'common' }),
			href: '/products'
		}
	]

	const loggedOutDrawerLinks = [
		{
			title: t('home', { ns: 'common' }),
			href: '/'
		},
		...headerLinks,
		{
			title: t('log-in', { ns: 'common' }),
			href: '/login',
			bold: true
		}
	]

	const loggedInDrawerLinks = [
		{
			title: t('home', { ns: 'common' }),
			href: '/'
		},
		...headerLinks,
		{
			title: t('my-dashboard', { ns: 'dashboard' }),
			...(onboard_status === PERSON.ONBOARD_STATUS.PENDING_APPROVAL
				? { href: '/booking/onboard' }
				: { href: `/dashboard/${user.dashboardType}` }),
			bold: true
		}
	]

	if (
		onboard_status === PERSON.ONBOARD_STATUS.ADMIN ||
		onboard_status === PERSON.ONBOARD_STATUS.ACTIVE_CONTRACT
	) {
		loggedInDrawerLinks.push({
			title: t('my-digital-keys', { ns: 'dashboard' }),
			href: `/dashboard/${user.dashboardType}/digital-key/unlock`,
			bold: true
		})
	}

	const handleLogout = () => {
		// Dispatch action to log the user out
		dispatch(logoutUser())
		router.push('/')
	}

	return (
		<header className='sticky top-0 z-40'>
			<div
				className={` ${
					stickyHeader ? 'header-sticky-container' : 'header-container'
				} ${
					variant === 'alternate-white' ? 'bg-brand-blue' : 'bg-brand-white'
				} font-serif border-b-2 ${!booking && 'border-brand-blue'}`}
			>
				<div
					className={`flex flex-row px-3 ${
						booking ? 'lg:px-11' : 'lg:px-8'
					} items-center justify-between h-20`}
				>
					<div
						className={`${
							searchBarVisible &&
							'border-r border-white flex items-center gap-1 mr-3 px-3 h-4/6'
						} lg:hidden`}
						onClick={() => setIsDrawerOpen(true)}
					>
						{searchBarVisible ? (
							<>
								<Image
									src={'/logo-glyph-white.svg'}
									width={29}
									height={29}
									alt='Stuf Logo'
								/>
								<CaretDown size={16} color='#ffffff' />
							</>
						) : (
							<FeatureIcon
								iconName={'List'}
								size={32}
								onClick={() => setIsDrawerOpen(true)}
								className={`${
									variant === 'alternate-white'
										? 'text-brand-white'
										: 'text-brand-blue'
								} hover:cursor-pointer lg:hidden`}
							/>
						)}
					</div>

					{/* Stuf Logo */}
					<div
						className={`flex items-start justify-start h-20 z-10 pt-6 ${
							searchBarVisible && !lg && 'hidden'
						}`}
					>
						<Link legacyBehavior variant='img' href='/'>
							{variant === 'alternate-white' ? (
								<Image
									src={'/stuf-logo-horizontal-white.svg'}
									width={107}
									height={28}
									alt='Stuf Logo'
								/>
							) : (
								<Image
									src={'/stuf-logo-horizontal-blue.svg'}
									width={107}
									height={28}
									alt='Stuf Logo'
								/>
							)}
						</Link>
					</div>
					{/* Nav Links */}
					{!booking && (
						<>
							<nav
								className={`space-x-8 hidden lg:flex ${
									variant === 'alternate-white' ? 'mx-8' : 'justify-center'
								}`}
							>
								{headerLinks.map((link) => (
									<Link
										legacyBehavior
										key={link.href}
										href={link.href}
										className={`${
											variant === 'alternate-white'
												? 'text-brand-white'
												: 'text-brand-blue'
										}`}
										variant=''
									>
										{link.title}
									</Link>
								))}
							</nav>
							{variant === 'alternate-white' && searchBarVisible && (
								<div className='flex-1'>
									<NavbarSearchBar isMapLoaded={isMapLoaded} />
								</div>
							)}
							<div className='hidden lg:flex items-center justify-end lg:gap-2 gap-8 ml-8'>
								<Button
									href={`tel:${phone}`}
									sx={(theme) => ({
										whiteSpace: 'nowrap',
										borderRadius: theme.shape.borderRadiusFullRounded,
										borderWidth: theme.shape.borderWidth
									})}
								>
									<p
										className={`${
											variant === 'alternate-white'
												? 'text-brand-white'
												: 'text-brand-blue'
										} font-bold mr-3 text-sm xl:text-base`}
									>
										{phone}
									</p>
								</Button>
								{/* Desktop */}
								{!isLoggedIn && (
									<>
										<div className='hidden lg:flex items-start justify-end'>
											<CtaButton
												as='a'
												className={`${
													variant === 'alternate-white'
														? 'bg-brand-blue text-brand-white'
														: 'bg-brand-white text-brand-blue'
												} text-xs lg:text-xs xl:text-base`}
												onClick={() => router.push('/login')}
												href='/login'
											>
												{t('log-in', { ns: 'common' })}
											</CtaButton>
										</div>
										<div className='hidden lg:flex items-start justify-end'>
											<CtaButton
												as='a'
												size='base'
												className={bookNowStyle}
												onClick={() => router.push('/search-available-units')}
												href='/search-available-units'
											>
												{t('book-now', { ns: 'common' })}
											</CtaButton>
										</div>
									</>
								)}
								{/* Desktop */}
								{isLoggedIn && (
									<>
										<p
											className={`font-base ${
												variant === 'alternate-white'
													? 'text-brand-white'
													: 'text-brand-blue'
											} capitalize`}
										>
											{`${user?.firstName} ${user?.lastName}`}
										</p>
										<div>
											<div className='header-initial-circle hidden lg:flex items-center justify-center'>
												<FeatureIcon
													iconName={'UserCircle'}
													size={32}
													className={`${
														variant === 'alternate-white'
															? 'text-brand-white'
															: 'text-brand-blue'
													} hover:cursor-pointer`}
													onClick={() => setIsMenuOpen(!isMenuOpen)}
												/>
											</div>
											<HeaderMenuPopup
												className=''
												isOpen={isMenuOpen}
												dashboardType={user?.dashboardType}
												handleLogout={handleLogout}
												variant={variant}
											/>
										</div>
									</>
								)}
								{/* Has unit in cart */}
								{unit[0]?.id && (
									<>
										<Badge
											badgeContent={unit.length}
											overlap='circular'
											sx={{
												'& .MuiBadge-badge': {
													color: 'white',
													fontWeight: 'bold',
													backgroundColor: theme.palette.primary.orange
												}
											}}
										>
											<ShoppingCart
												size={32}
												className={`my-auto cursor-pointer ${
													variant === 'alternate-white'
														? 'text-brand-white'
														: 'text-brand-blue'
												}`}
												onClick={() => dispatch(setCartOpen(true))}
											/>
										</Badge>
									</>
								)}
							</div>
						</>
					)}
					{booking && (
						<div className='hidden lg:block text-2xl'>
							{t('book-and-confirm', { ns: 'booking' })}
						</div>
					)}
					<div
						className={`flex flex-row gap-4 ${!booking && 'lg:hidden'} ${
							searchBarVisible && !lg && 'hidden'
						}`}
					>
						<a
							href={`tel:${phone}`}
							className='flex flex-row items-center'
							title='Contact us'
						>
							<Phone
								size={32}
								className={`my-auto ${
									variant === 'alternate-white'
										? 'text-brand-white'
										: 'text-brand-blue'
								}`}
							/>
						</a>
						{/* Has unit in cart */}
						{unit[0]?.id && !booking && (
							<>
								<Badge
									badgeContent={unit.length}
									overlap='circular'
									sx={{
										'& .MuiBadge-badge': {
											color: 'white',
											fontWeight: 'bold',
											backgroundColor: theme.palette.primary.orange
										}
									}}
								>
									<ShoppingCart
										size={32}
										className={`my-auto ${
											variant === 'alternate-white'
												? 'text-brand-white'
												: 'text-brand-blue'
										}`}
										onClick={() => dispatch(setCartOpen(true))}
									/>
								</Badge>
							</>
						)}
					</div>
				</div>
			</div>

			<Drawer
				classes={{ paper: '' }}
				anchor='left'
				ModalProps={{ className: 'lg:hidden' }}
				PaperProps={{ className: 'w-full md:w-80 lg:hidden' }}
				open={isDrawerOpen}
				onClose={() => setIsDrawerOpen(false)}
			>
				<div className='bg-brand-blue flex items-center justify-center h-20 relative px-6'>
					<div className='border-r border-white pr-3 '>
						<FeatureIcon
							iconName={'X'}
							size={32}
							className={' text-brand-white hover:cursor-pointer '}
							onClick={() => setIsDrawerOpen(false)}
						/>
					</div>
					<Image
						src={'/stuf-logo-horizontal-white.svg'}
						width={107}
						height={28}
						alt='Stuf Logo'
						className='mx-auto'
					/>
					<div className='pl-3'>
						<FeatureIcon
							iconName={'X'}
							size={32}
							className={' text-brand-blue '}
						/>
					</div>
				</div>

				<div className='flex-col flex flex-1 w-full bg-brand-white px-10 py-5'>
					{isLoggedIn &&
						loggedInDrawerLinks.map((link) => (
							<>
								{link.title === 'My dashboard' && (
									<hr
										className='bg-brand-orange border-none w-12 my-3'
										style={{ height: '3px' }}
									/>
								)}
								<div key={link.href} className='my-3'>
									<Link legacyBehavior href={link.href} bold={link.bold}>
										<span
											className={`uppercase ${
												link.bold ? 'text-brand-blue' : 'text-brand-black'
											}`}
										>
											{link.title}
										</span>
									</Link>
								</div>
							</>
						))}
					{isLoggedIn && (
						<div
							className='mb-5 text-brand-black font-serif uppercase hover:cursor-pointer'
							onClick={() => {
								setIsMenuOpen(!isMenuOpen)
								handleLogout()
							}}
						>
							{t('sign-out', { ns: 'common' })}
						</div>
					)}
					{!isLoggedIn &&
						loggedOutDrawerLinks.map((link) => (
							<>
								{link.title === 'Log in' && (
									<hr
										className='bg-brand-orange border-none w-12 my-3'
										style={{ height: '3px' }}
									/>
								)}
								<div key={link.href} className='my-3'>
									<Link legacyBehavior href={link.href} bold={link.bold}>
										<span
											className={`uppercase ${
												link.bold ? 'text-brand-blue' : 'text-brand-black'
											}`}
										>
											{link.title}
										</span>
									</Link>
								</div>
							</>
						))}
				</div>
				<div className='flex-col flex items-center pb-3'>
					<div>
						<Text variant='black'>{`${t('questions', { ns: 'common' })}`}</Text>
					</div>
					<div className='flex-row flex items-center'>
						<Text variant='black'>{`${t('call', { ns: 'common' })} `}</Text>

						<a
							href={`tel:${companyInfo.phone}`}
							className='font-serif mx-1 py-0 text-brand-black text-sm'
						>
							{companyInfo.phone}
						</a>
						<Text variant='black'>{`${t('or-chat-with-us', {
							ns: 'common'
						})}`}</Text>
					</div>
				</div>
				<div className='flex items-center bg-brand-blue h-20 px-6'>
					<Button
						href='/search-available-units'
						variant='stuf-outline-white'
						sx={(theme) => ({
							whiteSpace: 'nowrap',
							borderRadius: theme.shape.borderRadiusFullRounded,
							borderWidth: theme.shape.borderWidth,
							px: '30px',
							py: '6px'
						})}
						fullWidth
					>
						<p className='text-base capitalize font-bold text-brand-white'>
							{t('explore-locations', { ns: 'index' })}
						</p>
					</Button>
				</div>
			</Drawer>
			{!booking && <Cart />}
		</header>
	)
}

export default Header
