import { useEffect, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
	DesktopDatePicker,
	LocalizationProvider,
	MobileDatePicker,
	PickersDay,
	pickersDayClasses
} from '@mui/x-date-pickers'
import { TextField, Typography, useTheme } from '@mui/material'
import dateUtil from '../util/date'

const DateInput = ({
	disableCalendar = false,
	label,
	datePickerLabel,
	value = '',
	onChange,
	type = 'desktop',
	LabelProps,
	TextFieldProps,
	DesktopDatePickerProps,
	MobileDatePickerProps,
	allowNull = false
}) => {
	const theme = useTheme()

	const [date, setDate] = useState(new Date(value) || null)
	const [open, setOpen] = useState(false)

	const today = new Date()

	const handleChange = (newValue) => {
		if (!newValue && !allowNull) return

		if (!newValue) {
			onChange(null)
			return
		}
		// Convert from moment object to js Date object with .toDate()
		const dateObject = newValue.toDate()
		// Format with slashes
		const dateWithSlashes = dateUtil.formatDateSlashesMMDDYYYY(dateObject)

		setDate(dateObject)
		onChange(dateWithSlashes)
	}

	useEffect(() => {
		setDate(value)
	}, [value])

	return (
		<>
			<Typography
				fontSize={14}
				fontWeight={'bold'}
				textTransform='uppercase'
				sx={{ mb: 1 }}
				{...LabelProps}
			>
				{label}
			</Typography>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				{type === 'desktop' ? (
					<DesktopDatePicker
						open={!disableCalendar ? false : open}
						label={datePickerLabel}
						inputFormat='MM/DD/YYYY'
						value={date}
						onChange={handleChange}
						onClose={() => setOpen(false)}
						disableOpenPicker={true}
						PopperProps={{
							placement: 'bottom-start'
						}}
						renderDay={(day, selectedDays, pickersDayProps) => {
							return (
								<PickersDay
									{...pickersDayProps}
									sx={{
										[`&&.${pickersDayClasses.selected}`]: {
											color: theme.palette.text.primary,
											backgroundColor: theme.palette.primary.periwinkle
										},
										'&:hover': {
											backgroundColor: theme.palette.primary.periwinkle
										}
									}}
								/>
							)
						}}
						renderInput={({ inputRef }) => (
							<TextField
								ref={inputRef}
								fullWidth
								size='small'
								value={value}
								inputProps={{ readOnly: true }}
								onClick={() => setOpen(true)}
								{...TextFieldProps}
							/>
						)}
						{...DesktopDatePickerProps}
					/>
				) : (
					<MobileDatePicker
						label={datePickerLabel}
						inputFormat='MM/DD/YYYY'
						value={date}
						onChange={handleChange}
						showToolbar={false}
						minDate={today}
						renderDay={(day, selectedDays, pickersDayProps) => {
							return (
								<PickersDay
									{...pickersDayProps}
									sx={{
										[`&&.${pickersDayClasses.selected}`]: {
											color: theme.palette.text.primary,
											backgroundColor: theme.palette.primary.periwinkle
										},
										'&:hover': {
											backgroundColor: theme.palette.primary.periwinkle
										}
									}}
								/>
							)
						}}
						renderInput={(params) => (
							<TextField
								fullWidth
								size='small'
								value={value}
								inputProps={{ readOnly: true }}
								{...params}
								{...TextFieldProps}
							/>
						)}
						{...MobileDatePickerProps}
					/>
				)}
			</LocalizationProvider>
		</>
	)
}

export default DateInput
