import dynamic from 'next/dynamic'

import { useEffect, useState, useMemo } from 'react'
import { withTranslation } from 'react-i18next'

import { useJsApiLoader } from '@react-google-maps/api'

import facilityUtil from '../util/facility'
import { TRANSLATIONS } from '../util/constants'

import SwipeableMapOverlayCards from '../molecules/swipeable-map-overlay-cards'
import useMediaQuery from '@mui/material/useMediaQuery'

const libraries = ['geocoding', 'marker']

const NoSSRGMap = dynamic(() => import('../components/GMap'), {
	ssr: false
})

function LocationMapWithOverlayCards({
	facilities,
	selectedFacility,
	setSelectedFacility
}) {
	const [activeFacility, setActiveFacility] = useState() //Active map point
	const [cityMapData, setCityMapData] = useState()
	const [facilityCards, setFacilityCards] = useState()
	const smallWindow = useMediaQuery('(min-width:768px)')
	const { isLoaded: isMapLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
		libraries,
		version: 'weekly'
	})

	useEffect(() => {
		setCityMapData(facilityUtil.formatFacilitiesForMap(facilities))
		setFacilityCards(facilities)
	}, [facilities])

	useEffect(() => {
		if (cityMapData) {
			setActiveFacility(cityMapData[0]?.address)
		}
	}, [cityMapData])

	const memoizedMap = useMemo(
		() =>
			cityMapData ? (
				<NoSSRGMap
					cityMapData={cityMapData}
					center={activeFacility}
					disableDefaultUI={true}
				/>
			) : null,
		[cityMapData, activeFacility]
	)

	if (selectedFacility && cityMapData) {
		const facility = facilities.find(
			(facility) => facility.id === selectedFacility
		)
		const address = cityMapData.find(
			(location) => location.displayAddress === facility?.address
		)?.address

		if (activeFacility !== address) {
			setActiveFacility(address)
		}
	}

	return (
		Boolean(cityMapData) && (
			<div className='bg-white relative overflow-hidden flex flex-col sm:flex-row'>
				<div className='relative bg-gray-100 w-full h-[337px] md:h-[370px]'>
					{cityMapData ? (
						isMapLoaded ? (
							memoizedMap
						) : null
					) : (
						<div className='bg-gray-100'></div>
					)}
					<div className='absolute bottom-[11px] left-0  w-full  mx-3 md:mr-0'>
						<SwipeableMapOverlayCards
							facilities={facilities}
							facilityCards={facilityCards}
							selectedFacility={selectedFacility}
							setSelectedFacility={setSelectedFacility}
							cityMapData={cityMapData}
							smallWindow={smallWindow}
						/>
					</div>
				</div>
			</div>
		)
	)
}

export default withTranslation(TRANSLATIONS)(LocationMapWithOverlayCards)
