import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useTranslation } from 'next-i18next'

import InsuranceInfo from '../atoms/insurance-info'
import date from '../util/date'
import number from '../util/number'
import { TRANSLATIONS } from '../util/constants'
import moment from 'moment'

import {
	postCalculateMoveInEstimate,
	setInsuranceId,
	setMembershipId,
	setHasInsuranceSelected
} from '../slices/bookingSlice'

const PricingBreakdown = ({ pricingDetails }) => {
	const { t } = useTranslation(TRANSLATIONS)
	const dispatch = useDispatch()
	const [openInsurance, setOpenInsurance] = useState(false)
	const insurance = useSelector((state) => state.booking.insurance)
	const insurance_id = useSelector(
		(state) => state.booking.payment.insurance_id
	)
	const unitInfo = useSelector((state) => state.booking.unit)
	const facilityInfo = useSelector((state) => state.booking.facility)
	const paymentInfo = useSelector((state) => state.booking.payment)
	const cartOpen = useSelector((state) => state.booking.cartOpen)

	const {
		monthlyPrice,
		after19thMoveInPayment = 0,
		firstMonthRate = '0',
		firstMonthPMA = 0,
		total = 0,
		moveInCredits = 0,
		moveInDate = '',
		after19thInsurance,
		firstMonthInsurance,
		firstMonthMembership,
		after19thMembership,
		insurancePremium,
		membershipPrice,
		discountText,
		promoText,
		adminFee = { original: 30, now: 'free' },
		padlock = { original: 20, now: 'free' }
	} = pricingDetails

	const setInsuranceValue = (value) => {
		dispatch(
			postCalculateMoveInEstimate({
				body: {
					facility_id: facilityInfo.facility_id,
					monthly_price: unitInfo?.[0].price,
					move_in_date: paymentInfo.move_in_date,
					...(value && { insurance_id: value }),
					...(paymentInfo.membership_id && {
						membership_type_id: paymentInfo.membership_id
					}),
					...(paymentInfo.promo_id
						? { promo_id: paymentInfo.promo_id }
						: facilityInfo.discount_id !== null && {
								discount_id: facilityInfo.discount_id
						  })
				},
				...(cartOpen && { fields: ['schedule'] })
			})
		)
		dispatch(setInsuranceId(value))
		setOpenInsurance(false)
		dispatch(setHasInsuranceSelected(!!value))
	}

	const removeMembership = () => {
		dispatch(setMembershipId(null))
		dispatch(
			postCalculateMoveInEstimate({
				body: {
					facility_id: facilityInfo.facility_id,
					monthly_price: unitInfo?.[0].price,
					move_in_date: paymentInfo.move_in_date,
					...(value && { insurance_id: value }),
					...(paymentInfo.membership_id && {
						membership_type_id: paymentInfo.membership_id
					}),
					...(paymentInfo.promo_id
						? { promo_id: paymentInfo.promo_id }
						: facilityInfo.discount_id !== null && {
								discount_id: facilityInfo.discount_id
						  })
				},
				...(cartOpen && { fields: ['schedule'] })
			})
		)
	}

	return (
		<div className='font-serif flex-1' data-cy='pricing-breakdown'>
			<div className='flex justify-between text-brand-eerie-black'>
				<p className=''>
					{t('monthly-fee', {
						month: date.returnMonthFullName(
							date.formatDateDashesMMDDYYYYV2(moveInDate)
						),
						ns: 'common'
					})}
				</p>
				<p className=''>{number.formatPrice(parseFloat(monthlyPrice))}</p>
			</div>
			{!!insurancePremium && (
				<div className='flex justify-between items-center pl-4 mt-1'>
					<p className='text-brand-graphite text-sm'>
						{t('insurance', {
							ns: 'booking'
						})}
					</p>
					<div className='flex gap-3 items-center'>
						<Button
							sx={{ textDecoration: 'underline' }}
							onClick={() => setOpenInsurance(true)}
						>
							{t('change-plan', { ns: 'booking' })}
						</Button>
						<p className=''>{number.formatPrice(insurancePremium)}</p>
					</div>
				</div>
			)}
			{!!membershipPrice && (
				<div className='flex justify-between items-center pl-4 mt-1'>
					<p className='text-brand-graphite text-sm'>
						{t('stuf-blue', {
							ns: 'booking'
						})}
					</p>
					<div className='flex items-center gap-3'>
						<Button
							sx={{ textDecoration: 'underline' }}
							onClick={removeMembership}
						>
							{t('remove', { ns: 'booking' })}
						</Button>
						<p className=''>{number.formatPrice(membershipPrice)}</p>
					</div>
				</div>
			)}
			{firstMonthPMA > 0 && (
				<div className='flex justify-between pl-4 mt-2'>
					<div>
						<p className='text-brand-blue text-sm'>
							{t('partial-month-adjustment', {
								ns: 'booking'
							})}
						</p>
						<p className='text-brand-graphite text-xs'>
							{date.returnMonthAndDay(
								date.returnFirstDateOfMonth(
									date.formatDateDashesMMDDYYYYV2(moveInDate)
								)
							)}
							{' - '}
							{date.returnMonthAndDay(
								date.formatDateDashesMMDDYYYYV2(moveInDate)
							)}{' '}
							({firstMonthRate})
						</p>
					</div>

					<p className='text-brand-blue font-bold whitespace-nowrap'>
						-{number.formatPrice(firstMonthPMA)}
					</p>
				</div>
			)}
			{after19thMoveInPayment > 0 && (
				<>
					<div className='flex justify-between text-brand-eerie-black mt-4'>
						<p className=''>
							{t('monthly-fee', {
								month: date.returnNextMonthFullName(
									date.formatDateDashesMMDDYYYYV2(moveInDate)
								),
								ns: 'common'
							})}
						</p>
						<p className=''>{number.formatPrice(parseFloat(monthlyPrice))}</p>
					</div>
					{!!insurancePremium && (
						<div className='flex justify-between pl-4 mt-2'>
							<p className='text-brand-graphite text-sm'>
								{t('insurance', {
									ns: 'booking'
								})}
							</p>
							<p className=''>{number.formatPrice(insurancePremium)}</p>
						</div>
					)}
					{!!membershipPrice && (
						<div className='flex justify-between pl-4 mt-2'>
							<p className='text-brand-graphite text-sm'>
								{t('stuf-blue', {
									ns: 'booking'
								})}
							</p>
							<p className=''>{number.formatPrice(membershipPrice)}</p>
						</div>
					)}
				</>
			)}

			<div className='flex justify-between text-brand-eerie-black mt-4'>
				<p className='capitalize '>
					{t('admin-fee', {
						ns: 'common'
					})}
				</p>
				<p className='capitalize'>
					{t(adminFee.now, {
						ns: 'common'
					})}
				</p>
			</div>
			<div className='flex justify-between text-brand-eerie-black mt-4'>
				<p className='capitalize '>
					{t('padlock', {
						ns: 'common'
					})}
				</p>
				<p className='capitalize'>
					{t(padlock.now, {
						ns: 'common'
					})}
				</p>
			</div>
			{moveInCredits > 0 && (
				<div className='flex justify-between text-brand-eerie-black mt-4'>
					<div>
						<p className='text-brand-blue'>
							{t('discounts-applied', {
								ns: 'booking'
							})}
						</p>
						<p className='text-xs text-brand-graphite'>
							{promoText
								? `${t('promo-code', { ns: 'booking' })} - ${promoText}`
								: discountText
								? `${t('welcome-discount', {
										ns: 'booking'
								  })} - ${discountText}`
								: ''}
						</p>
					</div>

					<p className='text-brand-blue font-bold whitespace-nowrap'>
						-{number.formatPrice(moveInCredits)}
					</p>
				</div>
			)}
			<Divider sx={{ my: '16px' }} />
			<div className='bg-brand-beige py-3 px-4 rounded-xl mt-4 flex justify-between w-full'>
				<div>
					<p className='font-bold capitalize'>
						{t('due-upon-move-in', { ns: 'common' })}
					</p>
					<p className='text-brand-light-grey-4 text-sm'>
						{t('payment-on', {
							date: moment(moveInDate, 'MM-DD-YYYY').format('M/D/YYYY'),
							ns: 'booking'
						})}
					</p>
				</div>
				<p className='font-bold'>{number.formatPrice(total)}</p>
			</div>
			{after19thMoveInPayment > 0 && (
				<p
					className='font-bold text-brand-dark-blue mt-4'
					style={{ fontSize: '10px' }}
				>
					{t('after-19th-message', {
						month: date.returnNextMonthFullName(
							date.formatDateDashesMMDDYYYYV2(moveInDate)
						),
						ns: 'booking'
					})}
				</p>
			)}

			<Dialog
				open={openInsurance}
				onClose={() => setOpenInsurance(false)}
				PaperProps={{
					sx: {
						borderRadius: '25px'
					}
				}}
			>
				<div className='p-4'>
					<RadioGroup name='insurance' value={insurance_id}>
						{insurance.map((e, index) => {
							return (
								<div
									key={index}
									className={`border-2 rounded-xl font-serif cursor-pointer mb-4 ${
										insurance_id === e.id
											? 'border-brand-blue'
											: 'lg:border-white'
									}`}
									value={e.id}
									onClick={() => setInsuranceValue(e.id)}
									data-cy={`insurance-option-${e.id}`}
								>
									<InsuranceInfo insurance={e} />
								</div>
							)
						})}
						<FormControlLabel
							control={<Radio checked={insurance_id === null} />}
							value={null}
							label={t('i-have-my-own-coverage', {
								ns: 'booking'
							})}
							onClick={() => setInsuranceValue(null)}
							sx={{ ml: '12px' }}
							data-cy='insurance-option-null'
						/>
					</RadioGroup>
				</div>
			</Dialog>
		</div>
	)
}

export default PricingBreakdown
